import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import template from './modules/template'
import workers from './modules/workers'
import tags from './modules/tags'
import revisions from './modules/revisions'
import pendingOrders from './modules/pendingOrders'
import pendingVersions from './modules/pendingVersions'
import notifications from './modules/notifications'

Vue.use(Vuex)

let initialState = {
  workers: workers.state,
  tags: tags.state,
  revisions: revisions.state,
  pendingOrders: pendingOrders.state,
  pendingVersions: pendingVersions.state,
  notifications: notifications.state
}

export default new Vuex.Store({
  modules: {
    template,
    workers,
    tags,
    revisions,
    pendingOrders,
    pendingVersions,
    notifications
  },
  plugins:
  [
    createPersistedState(
      {
        paths: ['template'],
        storage: window.localStorage
      }
    ),
    createPersistedState(
      {
        paths: ['workers','revisions','tags','pendingOrders','pendingVersions','notifications'],
        storage: window.sessionStorage
      }
    ) 
  ],
  mutations: {
    reset (state) {
      Object.keys(state).forEach(key => {
        Object.assign(state[key], initialState[key])
      })
    }
  }
})