import { apolloClient } from '@/vue-apollo' 
import GET_WORKERS from '../graphql/getStaffMembers.gql'

export default {
  namespaced: true,
  state: {
    designersWorkers: [],
    animatorsWorkers: [],
    representativesWorkers: []
  },
  mutations:{
    setDesigners(state,designersWorkers){
      state.designersWorkers = designersWorkers
    },
    setAnimators(state,animatorsWorkers){
      state.animatorsWorkers = animatorsWorkers
    },
    setRepresentatives(state,representativesWorkers){
      state.representativesWorkers = representativesWorkers
    }
  },
  actions: {
    async getWorkers({commit}, staff) {
      const pageSize = 100
      let allWorkers = [];
      let hasNextPage = true;
      let endCursor = null;
      while(hasNextPage){
        const response = await apolloClient.query({
            query: GET_WORKERS,
            variables: {
            staff,
            page: endCursor
            }
        })
        const pageInfo = response.data.users.pageInfo
        const pageWorkers = response.data.users.edges.map(edge => edge.node);
        allWorkers = [...allWorkers, ...pageWorkers];
        hasNextPage = pageInfo.hasNextPage && pageWorkers.length === pageSize;
        endCursor = pageInfo.endCursor;
      }
      const activeWorkers = allWorkers.filter(worker => worker.isActive).map(worker => worker.email);


      switch (staff) {
        case 'Designers':
          commit('setDesigners',activeWorkers)
        break;
        case 'Animators':
          commit('setAnimators',activeWorkers)
        break;
        case 'Representatives':
          commit('setRepresentatives',activeWorkers)
        break;
      }
    }
  }
}