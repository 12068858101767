import Vue from 'vue'
import VueApollo from 'vue-apollo'
import { createApolloClient } from 'vue-cli-plugin-apollo/graphql-client'
import 'regenerator-runtime'
//import { InMemoryCache } from 'apollo-cache-inmemory'

// Install the vue plugin
Vue.use(VueApollo)

// Name of the localStorage item
const AUTH_TOKEN = 'wpl_token'

// Http endpoint
const httpEndpoint = process.env.VUE_APP_GRAPHQL_HTTP
//export const filesRoot = process.env.VUE_APP_FILES_ROOT || httpEndpoint.substr(0, httpEndpoint.indexOf('/graphql'))


//Vue.prototype.$filesRoot = filesRoot

// Config
const defaultOptions = {
  // You can use `https` for secure connection (recommended in production)
  httpEndpoint,
  // You can use `wss` for secure connection (recommended in production)
  // Use `null` to disable subscriptions
  wsEndpoint: process.env.VUE_APP_GRAPHQL_WS || null,
  // LocalStorage token
  tokenName: AUTH_TOKEN,
  // Enable Automatic Query persisting with Apollo Engine
  persisting: false,
  // Use websockets for everything (no HTTP)
  // You need to pass a `wsEndpoint` for this to work
  websocketsOnly: false,
  // Is being rendered on the server?
  ssr: false,
  //defaultHttpLink: true,
  // Override default apollo link
  // note: don't override httpLink here, specify httpLink options in the
  // httpLinkOptions property of defaultOptions.
   //link: myLink
   httpLinkOptions : {
    headers: {
      'Content-Language': 'en-US,en',
      'Accept-Language': 'en-US; q=0.9, en; q=0.5',
      'Referer': process.env.REFERER
    }
   },
  defaultHttpLink: true,

  cache: null,
  // Override the way the Authorization header is set
   getAuth: tokenName => {
     // get the authentication token from local storage if it exists
    const token = Vue.cookie.get(tokenName) ? 'JWT ' + Vue.cookie.get(tokenName) : null
    // return the headers to the context so httpLink can read them
    return  token || ''
   },

  // Additional ApolloClient options
  // apollo: { ... }

  // Client local data (see apollo-link-state)
  // clientState: { resolvers: { ... }, defaults: { ... } }
}
// Create apollo client

export const { apolloClient, wsClient } = createApolloClient({
  ...defaultOptions,
})

apolloClient.wsClient = wsClient


// Create vue apollo provider
export const apolloProvider = new VueApollo({
  defaultClient: apolloClient,
  connectToDevTools: true,
  defaultOptions: {
    $query: {
      fetchPolicy: 'cache-and-network',
      notifyOnNetworkStatusChange: true,
    },
  },
  errorHandler (error) {
    // eslint-disable-next-line no-console
    console.error('%cError', 'background: red; color: white; padding: 2px 4px; border-radius: 3px; font-weight: bold;', error.message)
  },
})
// Call this in the Vue app file
export function createProvider () {
 
  return apolloProvider
}
