<template>
  <li :class="dropdownClasses">
    <a 
    class="c-sidebar-nav-dropdown-toggle custom-hover" 
    :style="archive ? {paddingLeft: '65px', paddingRight: '10px'} : {}"
    :class="[isArtist(group) ? 'c-artist-sidebar' : 'c-rep-sidebar', { 'dropdown-archive': groupParent }]"
    @click="handleClick">
      <CIcon v-if="icon" v-bind="computedIcon"/>
      <i v-if="fontIcon" :class="['c-sidebar-nav-icon', fontIcon]"></i>
      {{name}}
      <template v-if="isArtist(group)">
        <span v-b-popover.hover.top="'Pending Revisions'" v-if="badge && badge.text" style="margin-top: 4px !important" :class="'sidebar-badge badge '+'badge-'+badge.text">{{pendingVersions[badge.text]}}</span>
      </template>
      <template v-else>
        <span v-b-popover.hover.top="'Pending Orders'" v-if="badge && badge.text" style="margin-top: 1px !important" :class="'sidebar-badge badge '+'badge-'+badge.text">{{pendingOrders[badge.text]}}</span>
      </template>
    </a>
    <ul class="c-sidebar-nav-dropdown-items" @click="itemClicked">
      <slot>
        <template v-for="(item, key) in items">
          <CXSidebarNavDropdown v-if="isNestedDropdown(item)" v-bind="item" :key="key"/>
          <CSidebarNavItem v-else v-bind="item" :key="key"/>
        </template>
      </slot>
    </ul>
  </li>
</template>

<script>
import CIcon from '@coreui/icons-vue/src/CIconRaw.vue'
import CSidebarNavItem from '@coreui/vue/src/components/template/CSidebarNavItem';
import { mapState } from 'vuex'

export default {
  name:'CXSidebarNavDropdown',
  components: {
    CIcon,
    CSidebarNavItem
  },
  props: {
    name: {
      type: String,
      default: () => {}
    },
    route: {
      type: String,
      validator: val => val.length > 0,
      default: () => {}
    },
    icon: {
      type:[String, Object],
      default: () => {}
    },
    fontIcon: {
      type: String,
      default: () => {}
    },    
    show: {
      type: Boolean,
      default: () => {}
    },
    items: {
      type: Array,
      default: () => {}
    },
    badge: {
      type:Object,
      default: () => {}
    },
    archive: {
      type: Boolean,
      default: false
    },
    groupParent: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      open: this.show,
      user: this.$cookie.get('wpl_username'),
      group: this.$cookie.get('wpl_user_group'),
      networkStatus: null,
    }
  },
  inject: {
    dropdownMode: {
      default: 'openActive'
    }
  },
  computed: {
      ...mapState('pendingOrders',['pendingOrders']),
      ...mapState('pendingVersions',['pendingVersions']),
    dropdownClasses () {
      return [
        'c-sidebar-nav-dropdown',
        { 'c-show': this.open }
      ]
    },
    computedIcon () {
      if (typeof this.icon === 'object') {
        const key = this.icon.size ? 'class' : 'customClasses'
        return {
          ...this.icon,
          [`${key}`]: this.icon.customClasses || 
                      ['c-sidebar-nav-icon', this.icon.className]
        }
      } else {
        return { customClasses: 'c-sidebar-nav-icon', name: this.icon }
      }
    },
  },
  watch: {
    show (val) {
      this.open = val
    },
    $route: {
      immediate: true,
      handler (route) {
        const mode = this.dropdownMode
        if (mode === 'close') {
          this.open = false
        } else if (mode === 'closeInactive' && this.route) {
          this.open = route.fullPath.includes(this.route)
        }  else if (mode === 'openActive' && !this.open && this.route) {
          this.open = route.fullPath.includes(this.route)
        }
        // else if (mode === 'noAction') {
        //   return
        // }
      }
    }
  },
  methods: {
    handleClick (e) {
      e.preventDefault()
      this.open = !this.open
      this.$emit('update:show', this.open)
    },
    itemClicked (e) {
      this.$emit('item-clicked', e)
    },
    isArtist(group){
      let state = null
      switch (group) {
        case 'Designers':
          state = true
        break;
        case 'Animators':
          state = true
        break;
        case 'Stamp Factory':
          state = true
        break;
        case 'Representatives':
          state = false
        break;
      }
      return state
    },
    isNestedDropdown(item) {
      return item._name === 'CXSidebarNavDropdown';
    }
  }
}
</script>
<style scoped>
.custom-hover:hover {
  text-decoration: none;
}
html:not([dir="rtl"]) .dropdown-archive.c-sidebar-nav-dropdown-toggle::after {
  margin-left: auto !important;
}
</style>
