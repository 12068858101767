import {
  cilEnvelopeClosed,
  cilEnvelopeOpen,
  cilMagnifyingGlass,
  cilMoon,
  cilSpeedometer,
  cilSpreadsheet,
  cilSun,
  cilAccountLogout,
  cilUser,
  cilXCircle,
  cilX,
  cil3d,
  cibElectron,
  cilLayers
} from '@coreui/icons'
import { logo } from './logo'

export const iconsSet = Object.assign({}, { logo }, {
  cilEnvelopeClosed,
  cilEnvelopeOpen,
  cilMagnifyingGlass,
  cilMoon,
  cilSpeedometer,
  cilSpreadsheet,
  cilSun,
  cilAccountLogout,
  cilUser,
  cilXCircle,
  cilX,
  cil3d,
  cibElectron,
  cilLayers
})