import Vue from 'vue'
import Router from 'vue-router'
Vue.use(Router)

export default new Router({
  mode: 'hash', // https://router.vuejs.org/api/#mode
  linkActiveClass: 'active',
  scrollBehavior: () => ({ y: 0 }),
  routes: configRoutes()
})

function configRoutes () {
  return [
    {
      path: '/',
      redirect: '/qa-dashboard',
      name: '',
      component: () => import(/* webpackChunkName: "theContainer" */'@/containers/TheContainer'),
      children: [
        {
          path: 'qa-dashboard',
          name: 'QADashboard',
          component: () => import(/* webpackChunkName: "artistDashboard" */ '@/views/dashboard/ArtistDashboard'),
          meta: {
            label: 'dashboard',
            requiresAuth: true, 
            permission: 'Representatives',
            state: 'detail'
          }
        },
        {
          path: 'dashboard',
          name: 'Dashboard',
          component: () => import(/* webpackChunkName: "artistDashboard" */ '@/views/dashboard/ArtistDashboard'),
          meta: {
            label: 'dashboard',
            requiresAuth: true, 
            permission: 'Designers',
            state: 'detail'
          }
        },
        {
          path: 'artist-workload',
          name: 'ArtistWorkload',
          component: () => import(/* webpackChunkName: "artistWorkload" */ '@/views/dashboard/ArtistWorkload'),
          meta: {
            label: 'dashboard',
            requiresAuth: true, 
            permission: 'RepDes',
            state: 'detail'
          }
        },
        {
          path: 'photologo',
          redirect: '/photologo/waiting-for-assign',
          name: 'Photologo',
          component: {
            render (c) { return c('router-view') }
          },
          children: [
            {
              path: '/photologo/waiting-for-assign/',
              name: 'P Waiting for Assign',
              component: () => import(/* webpackChunkName: "rep" */ '@/views/representatives/Representatives'),
              meta: {
                label: 'Awaiting assignment',
                requiresAuth: true, 
                permission: 'Representatives',
                state: 'list'
              }
            },
            {
              path: '/photologo/assigned/',
              name: 'P Assigned',
              component: () => import(/* webpackChunkName: "rep" */ '@/views/representatives/Representatives'),
              meta: {
                label: 'Assigned',
                requiresAuth: true, 
                permission: 'Representatives',
                state: 'list'
              }
            },
            {
              path: '/photologo/needs-attention/',
              name: 'P Needs Attention',
              component: () => import(/* webpackChunkName: "rep" */ '@/views/representatives/Representatives'),
              meta: {
                label: 'Needs Atention',
                requiresAuth: true, 
                permission: 'Representatives',
                state: 'list'
              }
            },
            {
              path: '/photologo/awaiting-representative-approval/',
              name: 'P Representative Approval',
              component: () => import(/* webpackChunkName: "rep" */ '@/views/representatives/Representatives'),
              meta: {
                label: 'Pending QA',
                requiresAuth: true, 
                permission: 'Representatives',
                state: 'list'
              }
            },
            {
              path: '/photologo/awaiting-user-approval/',
              name: 'P User Approval',
              component: () => import(/* webpackChunkName: "rep" */ '@/views/representatives/Representatives'),
              meta: {
                label: 'Pending approval',
                requiresAuth: true, 
                permission: 'Representatives',
                state: 'list'
              }
            },
            {
              path: '/photologo/revision-required/',
              name: 'P Revision Required',
              component: () => import(/* webpackChunkName: "rep" */ '@/views/representatives/Representatives'),
              meta: {
                label: 'Revision requested',
                requiresAuth: true, 
                permission: 'Representatives',
                state: 'list'
              }
            },
            {
              path: '/photologo/fulfilled/',
              name: 'P Fulfilled',
              component: () => import(/* webpackChunkName: "rep" */ '@/views/representatives/Representatives'),
              meta: {
                label: 'Fulfilled',
                requiresAuth: true, 
                permission: 'Representatives',
                state: 'list'
              }
            },
            {
              path: '/photologo/:id/',
              name: 'Detail',
              component: () => import(/* webpackChunkName: "repDetail" */ '@/views/representatives/SignatureDetail'),
              meta: {
                requiresAuth: true, 
                permission: 'Representatives',
                state: 'detail'
              }
            },
          ]
        },
        {
          path: 'lovemark',
          redirect: '/lovemark/waiting-for-assign',
          name: 'Lovemark',
          component: {
            render (c) { return c('router-view') }
          },
          children: [
            {
              path: '/lovemark/waiting-for-assign/',
              name: 'L Waiting for Assign',
              component: () => import(/* webpackChunkName: "rep" */ '@/views/representatives/Representatives'),
              meta: {
                label: 'Awaiting assignment',
                requiresAuth: true, 
                permission: 'Representatives',
                state: 'list'
              }
            },
            {
              path: '/lovemark/assigned/',
              name: 'L Assigned',
              component: () => import(/* webpackChunkName: "rep" */ '@/views/representatives/Representatives'),
              meta: {
                label: 'Assigned',
                requiresAuth: true, 
                permission: 'Representatives',
                state: 'list'
              }
            },
            {
              path: '/lovemark/needs-attention/',
              name: 'L Needs Attention',
              component: () => import(/* webpackChunkName: "rep" */ '@/views/representatives/Representatives'),
              meta: {
                label: 'Needs Atention',
                requiresAuth: true, 
                permission: 'Representatives',
                state: 'list'
              }
            },
            {
              path: '/lovemark/awaiting-representative-approval/',
              name: 'L Representative Approval',
              component: () => import(/* webpackChunkName: "rep" */ '@/views/representatives/Representatives'),
              meta: {
                label: 'Pending QA',
                requiresAuth: true, 
                permission: 'Representatives',
                state: 'list'
              }
            },
            {
              path: '/lovemark/awaiting-user-approval/',
              name: 'L User Approval',
              component: () => import(/* webpackChunkName: "rep" */ '@/views/representatives/Representatives'),
              meta: {
                label: 'Pending approval',
                requiresAuth: true, 
                permission: 'Representatives',
                state: 'list'
              }
            },
            {
              path: '/lovemark/revision-required/',
              name: 'L Revision Required',
              component: () => import(/* webpackChunkName: "rep" */ '@/views/representatives/Representatives'),
              meta: {
                label: 'Revision requested',
                requiresAuth: true, 
                permission: 'Representatives',
                state: 'list'
              }
            },
            {
              path: '/lovemark/fulfilled/',
              name: 'L Fulfilled',
              component: () => import(/* webpackChunkName: "rep" */ '@/views/representatives/Representatives'),
              meta: {
                label: 'Fulfilled',
                requiresAuth: true, 
                permission: 'Representatives',
                state: 'list'
              }
            },
            {
              path: '/lovemark/:id/',
              name: 'Lovemark Detail',
              component: () => import(/* webpackChunkName: "repDetail" */ '@/views/representatives/SignatureDetail'),
              meta: {
                requiresAuth: true, 
                permission: 'Representatives',
                state: 'detail'
              }
            },
          ]
        },
        {
          path: 'iconic-photologo',
          redirect: '/iconic-photologo/waiting-for-assign',
          name: 'Iconic Photologo',
          component: {
            render (c) { return c('router-view') }
          },
          children: [
            {
              path: '/iconic-photologo/waiting-for-assign/',
              name: 'IC Waiting for Assign',
              component: () => import(/* webpackChunkName: "rep" */ '@/views/representatives/Representatives'),
              meta: {
                label: 'Awaiting assignment',
                requiresAuth: true, 
                permission: 'Representatives',
                state: 'list'
              }
            },
            {
              path: '/iconic-photologo/assigned/',
              name: 'IC Assigned',
              component: () => import(/* webpackChunkName: "rep" */ '@/views/representatives/Representatives'),
              meta: {
                label: 'Assigned',
                requiresAuth: true, 
                permission: 'Representatives',
                state: 'list'
              }
            },
            {
              path: '/iconic-photologo/needs-attention/',
              name: 'IC Needs Attention',
              component: () => import(/* webpackChunkName: "rep" */ '@/views/representatives/Representatives'),
              meta: {
                label: 'Needs Atention',
                requiresAuth: true, 
                permission: 'Representatives',
                state: 'list'
              }
            },
            {
              path: '/iconic-photologo/awaiting-representative-approval/',
              name: 'IC Representative Approval',
              component: () => import(/* webpackChunkName: "rep" */ '@/views/representatives/Representatives'),
              meta: {
                label: 'Pending QA',
                requiresAuth: true, 
                permission: 'Representatives',
                state: 'list'
              }
            },
            {
              path: '/iconic-photologo/awaiting-user-approval/',
              name: 'IC User Approval',
              component: () => import(/* webpackChunkName: "rep" */ '@/views/representatives/Representatives'),
              meta: {
                label: 'Pending approval',
                requiresAuth: true, 
                permission: 'Representatives',
                state: 'list'
              }
            },
            {
              path: '/iconic-photologo/revision-required/',
              name: 'IC Revision Required',
              component: () => import(/* webpackChunkName: "rep" */ '@/views/representatives/Representatives'),
              meta: {
                label: 'Revision requested',
                requiresAuth: true, 
                permission: 'Representatives',
                state: 'list'
              }
            },
            {
              path: '/iconic-photologo/fulfilled/',
              name: 'IC Fulfilled',
              component: () => import(/* webpackChunkName: "rep" */ '@/views/representatives/Representatives'),
              meta: {
                label: 'Fulfilled',
                requiresAuth: true, 
                permission: 'Representatives',
                state: 'list'
              }
            },
            {
              path: '/iconic-photologo/:id/',
              name: 'Iconic Photologo Detail',
              component: () => import(/* webpackChunkName: "repDetail" */ '@/views/representatives/SignatureDetail'),
              meta: {
                requiresAuth: true, 
                permission: 'Representatives',
                state: 'detail'
              }
            },
          ]
        },
        {
          path: 'photologo-signature-brand',
          redirect: '/photologo-signature-brand/waiting-for-assign',
          name: 'Photologo Signature Brand',
          component: {
            render (c) { return c('router-view') }
          },
          children: [
            {
              path: '/photologo-signature-brand/waiting-for-assign/',
              name: 'PhSB Waiting for Assign',
              component: () => import(/* webpackChunkName: "rep" */ '@/views/representatives/Representatives'),
              meta: {
                label: 'Awaiting assignment',
                requiresAuth: true, 
                permission: 'Representatives',
                state: 'list'
              }
            },
            {
              path: '/photologo-signature-brand/assigned/',
              name: 'PhSB Assigned',
              component: () => import(/* webpackChunkName: "rep" */ '@/views/representatives/Representatives'),
              meta: {
                label: 'Assigned',
                requiresAuth: true, 
                permission: 'Representatives',
                state: 'list'
              }
            },
            {
              path: '/photologo-signature-brand/needs-attention/',
              name: 'PhSB Needs Attention',
              component: () => import(/* webpackChunkName: "rep" */ '@/views/representatives/Representatives'),
              meta: {
                label: 'Needs Atention',
                requiresAuth: true, 
                permission: 'Representatives',
                state: 'list'
              }
            },
            {
              path: '/photologo-signature-brand/awaiting-representative-approval/',
              name: 'PhSB Representative Approval',
              component: () => import(/* webpackChunkName: "rep" */ '@/views/representatives/Representatives'),
              meta: {
                label: 'Pending QA',
                requiresAuth: true, 
                permission: 'Representatives',
                state: 'list'
              }
            },
            {
              path: '/photologo-signature-brand/awaiting-user-approval/',
              name: 'PhSB User Approval',
              component: () => import(/* webpackChunkName: "rep" */ '@/views/representatives/Representatives'),
              meta: {
                label: 'Pending approval',
                requiresAuth: true, 
                permission: 'Representatives',
                state: 'list'
              }
            },
            {
              path: '/photologo-signature-brand/revision-required/',
              name: 'PhSB Revision Required',
              component: () => import(/* webpackChunkName: "rep" */ '@/views/representatives/Representatives'),
              meta: {
                label: 'Revision requested',
                requiresAuth: true, 
                permission: 'Representatives',
                state: 'list'
              }
            },
            {
              path: '/photologo-signature-brand/fulfilled/',
              name: 'PhSB Fulfilled',
              component: () => import(/* webpackChunkName: "rep" */ '@/views/representatives/Representatives'),
              meta: {
                label: 'Fulfilled',
                requiresAuth: true, 
                permission: 'Representatives',
                state: 'list'
              }
            },
            {
              path: '/photologo-signature-brand/:id/',
              name: 'Photologo Signature Brand Detail',
              component: () => import(/* webpackChunkName: "repDetail" */ '@/views/representatives/SignatureDetail'),
              meta: {
                requiresAuth: true, 
                permission: 'Representatives',
                state: 'detail'
              }
            },
          ]
        },
        {
          path: 'initials-pack',
          redirect: '/initials-pack/waiting-for-assign',
          name: 'Initials Pack',
          component: {
            render (c) { return c('router-view') }
          },
          children: [
            {
              path: '/initials-pack/waiting-for-assign/',
              name: 'INP Waiting for Assign',
              component: () => import(/* webpackChunkName: "rep" */ '@/views/representatives/Representatives'),
              meta: {
                label: 'Awaiting assignment',
                requiresAuth: true, 
                permission: 'Representatives',
                state: 'list'
              }
            },
            {
              path: '/initials-pack/assigned/',
              name: 'INP Assigned',
              component: () => import(/* webpackChunkName: "rep" */ '@/views/representatives/Representatives'),
              meta: {
                label: 'Assigned',
                requiresAuth: true, 
                permission: 'Representatives',
                state: 'list'
              }
            },
            {
              path: '/initials-pack/needs-attention/',
              name: 'INP Needs Attention',
              component: () => import(/* webpackChunkName: "rep" */ '@/views/representatives/Representatives'),
              meta: {
                label: 'Needs Atention',
                requiresAuth: true, 
                permission: 'Representatives',
                state: 'list'
              }
            },
            {
              path: '/initials-pack/awaiting-representative-approval/',
              name: 'INP Representative Approval',
              component: () => import(/* webpackChunkName: "rep" */ '@/views/representatives/Representatives'),
              meta: {
                label: 'Pending QA',
                requiresAuth: true, 
                permission: 'Representatives',
                state: 'list'
              }
            },
            {
              path: '/initials-pack/awaiting-user-approval/',
              name: 'INP User Approval',
              component: () => import(/* webpackChunkName: "rep" */ '@/views/representatives/Representatives'),
              meta: {
                label: 'Pending approval',
                requiresAuth: true, 
                permission: 'Representatives',
                state: 'list'
              }
            },
            {
              path: '/initials-pack/revision-required/',
              name: 'INP Revision Required',
              component: () => import(/* webpackChunkName: "rep" */ '@/views/representatives/Representatives'),
              meta: {
                label: 'Revision requested',
                requiresAuth: true, 
                permission: 'Representatives',
                state: 'list'
              }
            },
            {
              path: '/initials-pack/fulfilled/',
              name: 'INP Fulfilled',
              component: () => import(/* webpackChunkName: "rep" */ '@/views/representatives/Representatives'),
              meta: {
                label: 'Fulfilled',
                requiresAuth: true, 
                permission: 'Representatives',
                state: 'list'
              }
            },
            {
              path: '/initials-pack/:id/',
              name: 'Initials Pack Detail',
              component: () => import(/* webpackChunkName: "repDetail" */ '@/views/representatives/SignatureDetail'),
              meta: {
                requiresAuth: true, 
                permission: 'Representatives',
                state: 'detail'
              }
            },
          ]
        },
        {
          path: 'brand-seal-pack',
          redirect: '/brand-seal-pack/waiting-for-assign',
          name: 'Brand Seal Pack',
          component: {
            render (c) { return c('router-view') }
          },
          children: [
            {
              path: '/brand-seal-pack/waiting-for-assign/',
              name: 'BSP Waiting for Assign',
              component: () => import(/* webpackChunkName: "rep" */ '@/views/representatives/Representatives'),
              meta: {
                label: 'Awaiting assignment',
                requiresAuth: true, 
                permission: 'Representatives',
                state: 'list'
              }
            },
            {
              path: '/brand-seal-pack/assigned/',
              name: 'BSP Assigned',
              component: () => import(/* webpackChunkName: "rep" */ '@/views/representatives/Representatives'),
              meta: {
                label: 'Assigned',
                requiresAuth: true, 
                permission: 'Representatives',
                state: 'list'
              }
            },
            {
              path: '/brand-seal-pack/needs-attention/',
              name: 'BSP Needs Attention',
              component: () => import(/* webpackChunkName: "rep" */ '@/views/representatives/Representatives'),
              meta: {
                label: 'Needs Atention',
                requiresAuth: true, 
                permission: 'Representatives',
                state: 'list'
              }
            },
            {
              path: '/brand-seal-pack/awaiting-representative-approval/',
              name: 'BSP Representative Approval',
              component: () => import(/* webpackChunkName: "rep" */ '@/views/representatives/Representatives'),
              meta: {
                label: 'Pending QA',
                requiresAuth: true, 
                permission: 'Representatives',
                state: 'list'
              }
            },
            {
              path: '/brand-seal-pack/awaiting-user-approval/',
              name: 'BSP User Approval',
              component: () => import(/* webpackChunkName: "rep" */ '@/views/representatives/Representatives'),
              meta: {
                label: 'Pending approval',
                requiresAuth: true, 
                permission: 'Representatives',
                state: 'list'
              }
            },
            {
              path: '/brand-seal-pack/revision-required/',
              name: 'BSP Revision Required',
              component: () => import(/* webpackChunkName: "rep" */ '@/views/representatives/Representatives'),
              meta: {
                label: 'Revision requested',
                requiresAuth: true, 
                permission: 'Representatives',
                state: 'list'
              }
            },
            {
              path: '/brand-seal-pack/fulfilled/',
              name: 'BSP Fulfilled',
              component: () => import(/* webpackChunkName: "rep" */ '@/views/representatives/Representatives'),
              meta: {
                label: 'Fulfilled',
                requiresAuth: true, 
                permission: 'Representatives',
                state: 'list'
              }
            },
            {
              path: '/brand-seal-pack/:id/',
              name: 'Brand Seal Pack Detail',
              component: () => import(/* webpackChunkName: "repDetail" */ '@/views/representatives/SignatureDetail'),
              meta: {
                requiresAuth: true, 
                permission: 'Representatives',
                state: 'detail'
              }
            },
          ]
        },
        {
          path: 'stamps',
          redirect: '/stamps/not-yet-submitted',
          name: 'Stamps',
          component: {
            render (c) { return c('router-view') }
          },
          children: [
            {
              path: '/stamps/not-yet-submitted/',
              name: 'Not Yet Submitted',
              component: () => import(/* webpackChunkName: "rep" */ '@/views/representatives/Representatives'),
              meta: {
                label: 'Not Yet Submitted',
                requiresAuth: true, 
                permission: 'Representatives',
                state: 'list'
              }
            },
            {
              path: '/stamps/submitted/',
              name: 'Submitted',
              component: () => import(/* webpackChunkName: "rep" */ '@/views/representatives/Representatives'),
              meta: {
                label: 'Submitted',
                requiresAuth: true, 
                permission: 'Representatives',
                state: 'list'
              }
            },
            {
              path: '/stamps/fulfilled/',
              name: 'Fulfilled Stamps',
              component: () => import(/* webpackChunkName: "rep" */ '@/views/representatives/Representatives'),
              meta: {
                label: 'Fulfilled',
                requiresAuth: true, 
                permission: 'Representatives',
                state: 'list'
              }
            },
            {
              path: '/stamps/:id/',
              name: 'Stamp detail',
              component: () => import(/* webpackChunkName: "repDetail" */ '@/views/representatives/SignatureDetail'),
              meta: {
                label: 'Detail',
                requiresAuth: true, 
                permission: 'Representatives',
                state: 'detail'
              }
            },
          ]
        },
        {
          path: 'animations',
          redirect: '/animations/waiting-for-assign',
          name: 'Animations',
          component: {
            render (c) { return c('router-view') }
          },
          children: [
            {
              path: '/animations/waiting-for-assign/',
              name: 'A Waiting for Assign',
              component: () => import(/* webpackChunkName: "rep" */ '@/views/representatives/Representatives'),
              meta: {
                label: 'Awaiting assignment',
                requiresAuth: true, 
                permission: 'Representatives',
                state: 'list'
              }
            },
            {
              path: '/animations/assigned/',
              name: 'A Assigned',
              component: () => import(/* webpackChunkName: "rep" */ '@/views/representatives/Representatives'),
              meta: {
                label: 'Assigned',
                requiresAuth: true, 
                permission: 'Representatives',
                state: 'list'
              }
            },
            {
              path: '/animations/needs-attention/',
              name: 'A Needs Attention',
              component: () => import(/* webpackChunkName: "rep" */ '@/views/representatives/Representatives'),
              meta: {
                label: 'Needs Attention',
                requiresAuth: true, 
                permission: 'Representatives',
                state: 'list'
              }
            },
            {
              path: '/animations/awaiting-representative-approval/',
              name: 'A Representative Approval',
              component: () => import(/* webpackChunkName: "rep" */ '@/views/representatives/Representatives'),
              meta: {
                label: 'Pending QA',
                requiresAuth: true, 
                permission: 'Representatives',
                state: 'list'
              }
            },
            {
              path: '/animations/awaiting-user-approval/',
              name: 'A User Approval',
              component: () => import(/* webpackChunkName: "rep" */ '@/views/representatives/Representatives'),
              meta: {
                label: 'Pending approval',
                requiresAuth: true, 
                permission: 'Representatives',
                state: 'list'
              }
            },
            {
              path: '/animations/revision-required/',
              name: 'A Revision Required',
              component: () => import(/* webpackChunkName: "rep" */ '@/views/representatives/Representatives'),
              meta: {
                label: 'Revision requested',
                requiresAuth: true, 
                permission: 'Representatives',
                state: 'list'
              }
            },
            {
              path: '/animations/fulfilled/',
              name: 'A Fulfilled',
              component: () => import(/* webpackChunkName: "rep" */ '@/views/representatives/Representatives'),
              meta: {
                label: 'Fulfilled',
                requiresAuth: true, 
                permission: 'Representatives',
                state: 'list'
              }
            },
            {
              path: '/animations/:id/',
              name: 'Animations Detail',
              component: () => import(/* webpackChunkName: "repDetail" */ '@/views/representatives/SignatureDetail'),
              meta: {
                label: 'Detail',
                requiresAuth: true, 
                permission: 'Representatives',
                state: 'detail'
              }
            },
          ]
        },
        {
          path: 'ultimate-signature-kit',
          redirect: '/ultimate-signature-kit/waiting-for-assign',
          name: 'Ultimate Signature Kit',
          component: {
            render (c) { return c('router-view') }
          },
          children: [
            {
              path: '/ultimate-signature-kit/waiting-for-assign/',
              name: 'USK Waiting for Assign',
              component: () => import(/* webpackChunkName: "rep" */ '@/views/representatives/Representatives'),
              meta: {
                label: 'Awaiting assignment',
                requiresAuth: true, 
                permission: 'Representatives',
                state: 'list'
              }
            },
            {
              path: '/ultimate-signature-kit/assigned/',
              name: 'USK Assigned',
              component: () => import(/* webpackChunkName: "rep" */ '@/views/representatives/Representatives'),
              meta: {
                label: 'Assigned',
                requiresAuth: true, 
                permission: 'Representatives',
                state: 'list'
              }
            },
            {
              path: '/ultimate-signature-kit/needs-attention/',
              name: 'USK Needs Attention',
              component: () => import(/* webpackChunkName: "rep" */ '@/views/representatives/Representatives'),
              meta: {
                label: 'Needs Attention',
                requiresAuth: true, 
                permission: 'Representatives',
                state: 'list'
              }
            },
            {
              path: '/ultimate-signature-kit/awaiting-representative-approval/',
              name: 'USK Representative Approval',
              component: () => import(/* webpackChunkName: "rep" */ '@/views/representatives/Representatives'),
              meta: {
                label: 'Pending QA',
                requiresAuth: true, 
                permission: 'Representatives',
                state: 'list'
              }
            },
            {
              path: '/ultimate-signature-kit/awaiting-user-approval/',
              name: 'USK User Approval',
              component: () => import(/* webpackChunkName: "rep" */ '@/views/representatives/Representatives'),
              meta: {
                label: 'Pending approval',
                requiresAuth: true, 
                permission: 'Representatives',
                state: 'list'
              }
            },
            {
              path: '/ultimate-signature-kit/revision-required/',
              name: 'USK Revision Required',
              component: () => import(/* webpackChunkName: "rep" */ '@/views/representatives/Representatives'),
              meta: {
                label: 'Revision requested',
                requiresAuth: true, 
                permission: 'Representatives',
                state: 'list'
              }
            },
            {
              path: '/ultimate-signature-kit/fulfilled/',
              name: 'USK Fulfilled',
              component: () => import(/* webpackChunkName: "rep" */ '@/views/representatives/Representatives'),
              meta: {
                label: 'Fulfilled',
                requiresAuth: true, 
                permission: 'Representatives',
                state: 'list'
              }
            },
            {
              path: '/ultimate-signature-kit/:id/',
              name: 'Ultimte Signature Kit Detail',
              component: () => import(/* webpackChunkName: "repDetail" */ '@/views/representatives/SignatureDetail'),
              meta: {
                label: 'Detail',
                requiresAuth: true, 
                permission: 'Representatives',
                state: 'detail'
              }
            },
          ]
        },
        {
          path: 'initials-edition',
          redirect: '/initials-edition/waiting-for-assign',
          name: 'Initials',
          component: {
            render (c) { return c('router-view') }
          },
          children: [
            {
              path: '/initials-edition/waiting-for-assign/',
              name: 'I Waiting for Assign',
              component: () => import(/* webpackChunkName: "rep" */ '@/views/representatives/Representatives'),
              meta: {
                label: 'Awaiting assignment',
                requiresAuth: true, 
                permission: 'Representatives',
                state: 'list'
              }
            },
            {
              path: '/initials-edition/assigned/',
              name: 'I Assigned',
              component: () => import(/* webpackChunkName: "rep" */ '@/views/representatives/Representatives'),
              meta: {
                label: 'Assigned',
                requiresAuth: true, 
                permission: 'Representatives',
                state: 'list'
              }
            },
            {
              path: '/initials-edition/needs-attention/',
              name: 'I Needs Attention',
              component: () => import(/* webpackChunkName: "rep" */ '@/views/representatives/Representatives'),
              meta: {
                label: 'Needs Attention',
                requiresAuth: true, 
                permission: 'Representatives',
                state: 'list'
              }
            },
            {
              path: '/initials-edition/awaiting-representative-approval/',
              name: 'I Representative Approval',
              component: () => import(/* webpackChunkName: "rep" */ '@/views/representatives/Representatives'),
              meta: {
                label: 'Pending QA',
                requiresAuth: true, 
                permission: 'Representatives',
                state: 'list'
              }
            },
            {
              path: '/initials-edition/awaiting-user-approval/',
              name: 'I User Approval',
              component: () => import(/* webpackChunkName: "rep" */ '@/views/representatives/Representatives'),
              meta: {
                label: 'Pending approval',
                requiresAuth: true, 
                permission: 'Representatives',
                state: 'list'
              }
            },
            {
              path: '/initials-edition/revision-required/',
              name: 'I Revision Required',
              component: () => import(/* webpackChunkName: "rep" */ '@/views/representatives/Representatives'),
              meta: {
                label: 'Revision requested',
                requiresAuth: true, 
                permission: 'Representatives',
                state: 'list'
              }
            },
            {
              path: '/initials-edition/fulfilled/',
              name: 'I Fulfilled',
              component: () => import(/* webpackChunkName: "rep" */ '@/views/representatives/Representatives'),
              meta: {
                label: 'Fulfilled',
                requiresAuth: true, 
                permission: 'Representatives',
                state: 'list'
              }
            },
            {
              path: '/initials-edition/:id/',
              name: 'Initials Detail',
              component: () => import(/* webpackChunkName: "repDetail" */ '@/views/representatives/SignatureDetail'),
              meta: {
                label: 'Detail',
                requiresAuth: true, 
                permission: 'Representatives',
                state: 'detail'
              }
            },
          ]
        },
        {
          path: 'extension-kit',
          redirect: '/extension-kit/waiting-for-assign',
          name: 'Extension',
          component: {
            render (c) { return c('router-view') }
          },
          children: [
            {
              path: '/extension-kit/waiting-for-assign/',
              name: 'E Waiting for Assign',
              component: () => import(/* webpackChunkName: "rep" */ '@/views/representatives/Representatives'),
              meta: {
                label: 'Awaiting assignment',
                requiresAuth: true, 
                permission: 'Representatives',
                state: 'list'
              }
            },
            {
              path: '/extension-kit/assigned/',
              name: 'E Assigned',
              component: () => import(/* webpackChunkName: "rep" */ '@/views/representatives/Representatives'),
              meta: {
                label: 'Assigned',
                requiresAuth: true, 
                permission: 'Representatives',
                state: 'list'
              }
            },
            {
              path: '/extension-kit/needs-attention/',
              name: 'E Needs Attention',
              component: () => import(/* webpackChunkName: "rep" */ '@/views/representatives/Representatives'),
              meta: {
                label: 'Needs Attention',
                requiresAuth: true, 
                permission: 'Representatives',
                state: 'list'
              }
            },
            {
              path: '/extension-kit/awaiting-representative-approval/',
              name: 'E Representative Approval',
              component: () => import(/* webpackChunkName: "rep" */ '@/views/representatives/Representatives'),
              meta: {
                label: 'Pending QA',
                requiresAuth: true, 
                permission: 'Representatives',
                state: 'list'
              }
            },
            {
              path: '/extension-kit/awaiting-user-approval/',
              name: 'E User Approval',
              component: () => import(/* webpackChunkName: "rep" */ '@/views/representatives/Representatives'),
              meta: {
                label: 'Pending approval',
                requiresAuth: true, 
                permission: 'Representatives',
                state: 'list'
              }
            },
            {
              path: '/extension-kit/revision-required/',
              name: 'E Revision Required',
              component: () => import(/* webpackChunkName: "rep" */ '@/views/representatives/Representatives'),
              meta: {
                label: 'Revision requested',
                requiresAuth: true, 
                permission: 'Representatives',
                state: 'list'
              }
            },
            {
              path: '/extension-kit/fulfilled/',
              name: 'E Fulfilled',
              component: () => import(/* webpackChunkName: "rep" */ '@/views/representatives/Representatives'),
              meta: {
                label: 'Fulfilled',
                requiresAuth: true, 
                permission: 'Representatives',
                state: 'list'
              }
            },
            {
              path: '/extension-kit/:id/',
              name: 'Extension Kit Detail',
              component: () => import(/* webpackChunkName: "repDetail" */ '@/views/representatives/SignatureDetail'),
              meta: {
                label: 'Detail',
                requiresAuth: true, 
                permission: 'Representatives',
                state: 'detail'
              }
            },
          ]
        },
        {
          path: 'metallic-edition',
          redirect: '/metallic-edition/waiting-for-assign',
          name: 'Metallic',
          component: {
            render (c) { return c('router-view') }
          },
          children: [
            {
              path: '/metallic-edition/waiting-for-assign/',
              name: 'M Waiting for Assign',
              component: () => import(/* webpackChunkName: "rep" */ '@/views/representatives/Representatives'),
              meta: {
                label: 'Awaiting assignment',
                requiresAuth: true, 
                permission: 'Representatives',
                state: 'list'
              }
            },
            {
              path: '/metallic-edition/assigned/',
              name: 'M Assigned',
              component: () => import(/* webpackChunkName: "rep" */ '@/views/representatives/Representatives'),
              meta: {
                label: 'Assigned',
                requiresAuth: true, 
                permission: 'Representatives',
                state: 'list'
              }
            },
            {
              path: '/metallic-edition/needs-attention/',
              name: 'M Needs Attention',
              component: () => import(/* webpackChunkName: "rep" */ '@/views/representatives/Representatives'),
              meta: {
                label: 'Needs Attention',
                requiresAuth: true, 
                permission: 'Representatives',
                state: 'list'
              }
            },
            {
              path: '/metallic-edition/awaiting-representative-approval/',
              name: 'M Representative Approval',
              component: () => import(/* webpackChunkName: "rep" */ '@/views/representatives/Representatives'),
              meta: {
                label: 'Pending QA',
                requiresAuth: true, 
                permission: 'Representatives',
                state: 'list'
              }
            },
            {
              path: '/metallic-edition/awaiting-user-approval/',
              name: 'M User Approval',
              component: () => import(/* webpackChunkName: "rep" */ '@/views/representatives/Representatives'),
              meta: {
                label: 'Pending approval',
                requiresAuth: true, 
                permission: 'Representatives',
                state: 'list'
              }
            },
            {
              path: '/metallic-edition/revision-required/',
              name: 'M Revision Required',
              component: () => import(/* webpackChunkName: "rep" */ '@/views/representatives/Representatives'),
              meta: {
                label: 'Revision requested',
                requiresAuth: true, 
                permission: 'Representatives',
                state: 'list'
              }
            },
            {
              path: '/metallic-edition/fulfilled/',
              name: 'M Fulfilled',
              component: () => import(/* webpackChunkName: "rep" */ '@/views/representatives/Representatives'),
              meta: {
                label: 'Fulfilled',
                requiresAuth: true, 
                permission: 'Representatives',
                state: 'list'
              }
            },
            {
              path: '/metallic-edition/:id/',
              name: 'Metallic Edition Detail',
              component: () => import(/* webpackChunkName: "repDetail" */ '@/views/representatives/SignatureDetail'),
              meta: {
                label: 'Detail',
                requiresAuth: true, 
                permission: 'Representatives',
                state: 'detail'
              }
            },
          ]
        },
        {
          path: 'watercolor-edition',
          redirect: '/watercolor-edition/waiting-for-assign',
          name: 'Watercolor',
          component: {
            render (c) { return c('router-view') }
          },
          children: [
            {
              path: '/watercolor-edition/waiting-for-assign/',
              name: 'W Waiting for Assign',
              component: () => import(/* webpackChunkName: "rep" */ '@/views/representatives/Representatives'),
              meta: {
                label: 'Awaiting assignment',
                requiresAuth: true, 
                permission: 'Representatives',
                state: 'list'
              }
            },
            {
              path: '/watercolor-edition/assigned/',
              name: 'W Assigned',
              component: () => import(/* webpackChunkName: "rep" */ '@/views/representatives/Representatives'),
              meta: {
                label: 'Assigned',
                requiresAuth: true, 
                permission: 'Representatives',
                state: 'list'
              }
            },
            {
              path: '/watercolor-edition/needs-attention/',
              name: 'W Needs Attention',
              component: () => import(/* webpackChunkName: "rep" */ '@/views/representatives/Representatives'),
              meta: {
                label: 'Needs Attention',
                requiresAuth: true, 
                permission: 'Representatives',
                state: 'list'
              }
            },
            {
              path: '/watercolor-edition/awaiting-representative-approval/',
              name: 'W Representative Approval',
              component: () => import(/* webpackChunkName: "rep" */ '@/views/representatives/Representatives'),
              meta: {
                label: 'Pending QA',
                requiresAuth: true, 
                permission: 'Representatives',
                state: 'list'
              }
            },
            {
              path: '/watercolor-edition/awaiting-user-approval/',
              name: 'W User Approval',
              component: () => import(/* webpackChunkName: "rep" */ '@/views/representatives/Representatives'),
              meta: {
                label: 'Pending approval',
                requiresAuth: true, 
                permission: 'Representatives',
                state: 'list'
              }
            },
            {
              path: '/watercolor-edition/revision-required/',
              name: 'W Revision Required',
              component: () => import(/* webpackChunkName: "rep" */ '@/views/representatives/Representatives'),
              meta: {
                label: 'Revision requested',
                requiresAuth: true, 
                permission: 'Representatives',
                state: 'list'
              }
            },
            {
              path: '/watercolor-edition/fulfilled/',
              name: 'W Fulfilled',
              component: () => import(/* webpackChunkName: "rep" */ '@/views/representatives/Representatives'),
              meta: {
                label: 'Fulfilled',
                requiresAuth: true, 
                permission: 'Representatives',
                state: 'list'
              }
            },
            {
              path: '/watercolor-edition/:id/',
              name: 'Watercolor Edition Detail',
              component: () => import(/* webpackChunkName: "repDetail" */ '@/views/representatives/SignatureDetail'),
              meta: {
                label: 'Detail',
                requiresAuth: true, 
                permission: 'Representatives',
                state: 'detail'
              }
            },
          ]
        },
        {
          path: 'vintage-edition',
          redirect: '/vintage-edition/waiting-for-assign',
          name: 'Vintage',
          component: {
            render (c) { return c('router-view') }
          },
          children: [
            {
              path: '/vintage-edition/waiting-for-assign/',
              name: 'V Waiting for Assign',
              component: () => import(/* webpackChunkName: "rep" */ '@/views/representatives/Representatives'),
              meta: {
                label: 'Awaiting assignment',
                requiresAuth: true, 
                permission: 'Representatives',
                state: 'list'
              }
            },
            {
              path: '/vintage-edition/assigned/',
              name: 'V Assigned',
              component: () => import(/* webpackChunkName: "rep" */ '@/views/representatives/Representatives'),
              meta: {
                label: 'Assigned',
                requiresAuth: true, 
                permission: 'Representatives',
                state: 'list'
              }
            },
            {
              path: '/vintage-edition/needs-attention/',
              name: 'V Needs Attention',
              component: () => import(/* webpackChunkName: "rep" */ '@/views/representatives/Representatives'),
              meta: {
                label: 'Needs Attention',
                requiresAuth: true, 
                permission: 'Representatives',
                state: 'list'
              }
            },
            {
              path: '/vintage-edition/awaiting-representative-approval/',
              name: 'V Representative Approval',
              component: () => import(/* webpackChunkName: "rep" */ '@/views/representatives/Representatives'),
              meta: {
                label: 'Pending QA',
                requiresAuth: true, 
                permission: 'Representatives',
                state: 'list'
              }
            },
            {
              path: '/vintage-edition/awaiting-user-approval/',
              name: 'V User Approval',
              component: () => import(/* webpackChunkName: "rep" */ '@/views/representatives/Representatives'),
              meta: {
                label: 'Pending approval',
                requiresAuth: true, 
                permission: 'Representatives',
                state: 'list'
              }
            },
            {
              path: '/vintage-edition/revision-required/',
              name: 'V Revision Required',
              component: () => import(/* webpackChunkName: "rep" */ '@/views/representatives/Representatives'),
              meta: {
                label: 'Revision requested',
                requiresAuth: true, 
                permission: 'Representatives',
                state: 'list'
              }
            },
            {
              path: '/vintage-edition/fulfilled/',
              name: 'V Fulfilled',
              component: () => import(/* webpackChunkName: "rep" */ '@/views/representatives/Representatives'),
              meta: {
                label: 'Fulfilled',
                requiresAuth: true, 
                permission: 'Representatives',
                state: 'list'
              }
            },
            {
              path: '/vintage-edition/:id/',
              name: 'Vintage Edition Detail',
              component: () => import(/* webpackChunkName: "repDetail" */ '@/views/representatives/SignatureDetail'),
              meta: {
                label: 'Detail',
                requiresAuth: true, 
                permission: 'Representatives',
                state: 'detail'
              }
            },
          ]
        },
        {
          path: 'photostyle-edition',
          redirect: '/photostyle-edition/waiting-for-assign',
          name: 'Photostyle',
          component: {
            render (c) { return c('router-view') }
          },
          children: [
            {
              path: '/photostyle-edition/waiting-for-assign/',
              name: 'Ps Waiting for Assign',
              component: () => import(/* webpackChunkName: "rep" */ '@/views/representatives/Representatives'),
              meta: {
                label: 'Awaiting assignment',
                requiresAuth: true, 
                permission: 'Representatives',
                state: 'list'
              }
            },
            {
              path: '/photostyle-edition/assigned/',
              name: 'Ps Assigned',
              component: () => import(/* webpackChunkName: "rep" */ '@/views/representatives/Representatives'),
              meta: {
                label: 'Assigned',
                requiresAuth: true, 
                permission: 'Representatives',
                state: 'list'
              }
            },
            {
              path: '/photostyle-edition/needs-attention/',
              name: 'Ps Needs Attention',
              component: () => import(/* webpackChunkName: "rep" */ '@/views/representatives/Representatives'),
              meta: {
                label: 'Needs Attention',
                requiresAuth: true, 
                permission: 'Representatives',
                state: 'list'
              }
            },
            {
              path: '/photostyle-edition/awaiting-representative-approval/',
              name: 'Ps Representative Approval',
              component: () => import(/* webpackChunkName: "rep" */ '@/views/representatives/Representatives'),
              meta: {
                label: 'Pending QA',
                requiresAuth: true, 
                permission: 'Representatives',
                state: 'list'
              }
            },
            {
              path: '/photostyle-edition/awaiting-user-approval/',
              name: 'Ps User Approval',
              component: () => import(/* webpackChunkName: "rep" */ '@/views/representatives/Representatives'),
              meta: {
                label: 'Pending approval',
                requiresAuth: true, 
                permission: 'Representatives',
                state: 'list'
              }
            },
            {
              path: '/photostyle-edition/revision-required/',
              name: 'Ps Revision Required',
              component: () => import(/* webpackChunkName: "rep" */ '@/views/representatives/Representatives'),
              meta: {
                label: 'Revision requested',
                requiresAuth: true, 
                permission: 'Representatives',
                state: 'list'
              }
            },
            {
              path: '/photostyle-edition/fulfilled/',
              name: 'Ps Fulfilled',
              component: () => import(/* webpackChunkName: "rep" */ '@/views/representatives/Representatives'),
              meta: {
                label: 'Fulfilled',
                requiresAuth: true, 
                permission: 'Representatives',
                state: 'list'
              }
            },
            {
              path: '/photostyle-edition/:id/',
              name: 'Photostyle Edition Detail',
              component: () => import(/* webpackChunkName: "repDetail" */ '@/views/representatives/SignatureDetail'),
              meta: {
                label: 'Detail',
                requiresAuth: true, 
                permission: 'Representatives',
                state: 'detail'
              }
            },
          ]
        },
        {
          path: 'social-profile-pack',
          redirect: '/social-profile-pack/waiting-for-assign',
          name: 'Social Profile Pack',
          component: {
            render (c) { return c('router-view') }
          },
          children: [
            {
              path: '/social-profile-pack/waiting-for-assign/',
              name: 'So Waiting for Assign',
              component: () => import(/* webpackChunkName: "rep" */ '@/views/representatives/Representatives'),
              meta: {
                label: 'Awaiting assignment',
                requiresAuth: true, 
                permission: 'Representatives',
                state: 'list'
              }
            },
            {
              path: '/social-profile-pack/assigned/',
              name: 'So Assigned',
              component: () => import(/* webpackChunkName: "rep" */ '@/views/representatives/Representatives'),
              meta: {
                label: 'Assigned',
                requiresAuth: true, 
                permission: 'Representatives',
                state: 'list'
              }
            },
            {
              path: '/social-profile-pack/needs-attention/',
              name: 'So Needs Attention',
              component: () => import(/* webpackChunkName: "rep" */ '@/views/representatives/Representatives'),
              meta: {
                label: 'Needs Attention',
                requiresAuth: true, 
                permission: 'Representatives',
                state: 'list'
              }
            },
            {
              path: '/social-profile-pack/awaiting-representative-approval/',
              name: 'So Representative Approval',
              component: () => import(/* webpackChunkName: "rep" */ '@/views/representatives/Representatives'),
              meta: {
                label: 'Pending QA',
                requiresAuth: true, 
                permission: 'Representatives',
                state: 'list'
              }
            },
            {
              path: '/social-profile-pack/awaiting-user-approval/',
              name: 'So User Approval',
              component: () => import(/* webpackChunkName: "rep" */ '@/views/representatives/Representatives'),
              meta: {
                label: 'Pending approval',
                requiresAuth: true, 
                permission: 'Representatives',
                state: 'list'
              }
            },
            {
              path: '/social-profile-pack/revision-required/',
              name: 'So Revision Required',
              component: () => import(/* webpackChunkName: "rep" */ '@/views/representatives/Representatives'),
              meta: {
                label: 'Revision requested',
                requiresAuth: true, 
                permission: 'Representatives',
                state: 'list'
              }
            },
            {
              path: '/social-profile-pack/fulfilled/',
              name: 'So Fulfilled',
              component: () => import(/* webpackChunkName: "rep" */ '@/views/representatives/Representatives'),
              meta: {
                label: 'Fulfilled',
                requiresAuth: true, 
                permission: 'Representatives',
                state: 'list'
              }
            },
            {
              path: '/social-profile-pack/:id/',
              name: 'Social Profile Pack Detail',
              component: () => import(/* webpackChunkName: "repDetail" */ '@/views/representatives/SignatureDetail'),
              meta: {
                label: 'Detail',
                requiresAuth: true, 
                permission: 'Representatives',
                state: 'detail'
              }
            },
          ]
        },
        {
          path: 'all-purpose-pack',
          redirect: '/all-purpose/waiting-for-assign',
          name: 'All Purpose Pack',
          component: {
            render (c) { return c('router-view') }
          },
          children: [
            {
              path: '/all-purpose/waiting-for-assign/',
              name: 'Alp Waiting for Assign',
              component: () => import(/* webpackChunkName: "rep" */ '@/views/representatives/Representatives'),
              meta: {
                label: 'Awaiting assignment',
                requiresAuth: true, 
                permission: 'Representatives',
                state: 'list'
              }
            },
            {
              path: '/all-purpose/assigned/',
              name: 'Alp Assigned',
              component: () => import(/* webpackChunkName: "rep" */ '@/views/representatives/Representatives'),
              meta: {
                label: 'Assigned',
                requiresAuth: true, 
                permission: 'Representatives',
                state: 'list'
              }
            },
            {
              path: '/all-purpose/needs-attention/',
              name: 'Alp Needs Attention',
              component: () => import(/* webpackChunkName: "rep" */ '@/views/representatives/Representatives'),
              meta: {
                label: 'Needs Attention',
                requiresAuth: true, 
                permission: 'Representatives',
                state: 'list'
              }
            },
            {
              path: '/all-purpose/awaiting-representative-approval/',
              name: 'Alp Representative Approval',
              component: () => import(/* webpackChunkName: "rep" */ '@/views/representatives/Representatives'),
              meta: {
                label: 'Pending QA',
                requiresAuth: true, 
                permission: 'Representatives',
                state: 'list'
              }
            },
            {
              path: '/all-purpose/awaiting-user-approval/',
              name: 'Alp User Approval',
              component: () => import(/* webpackChunkName: "rep" */ '@/views/representatives/Representatives'),
              meta: {
                label: 'Pending approval',
                requiresAuth: true, 
                permission: 'Representatives',
                state: 'list'
              }
            },
            {
              path: '/all-purpose/revision-required/',
              name: 'Alp Revision Required',
              component: () => import(/* webpackChunkName: "rep" */ '@/views/representatives/Representatives'),
              meta: {
                label: 'Revision requested',
                requiresAuth: true, 
                permission: 'Representatives',
                state: 'list'
              }
            },
            {
              path: '/all-purpose/fulfilled/',
              name: 'Alp Fulfilled',
              component: () => import(/* webpackChunkName: "rep" */ '@/views/representatives/Representatives'),
              meta: {
                label: 'Fulfilled',
                requiresAuth: true, 
                permission: 'Representatives',
                state: 'list'
              }
            },
            {
              path: '/all-purpose/:id/',
              name: 'All Purpose Pack Detail',
              component: () => import(/* webpackChunkName: "repDetail" */ '@/views/representatives/SignatureDetail'),
              meta: {
                label: 'Detail',
                requiresAuth: true, 
                permission: 'Representatives',
                state: 'detail'
              }
            },
          ]
        },
        {
          path: 'smart-email',
          redirect: '/smart-email/waiting-for-assign',
          name: 'Smart Email Signature',
          component: {
            render (c) { return c('router-view') }
          },
          children: [
            {
              path: '/smart-email/waiting-for-assign/',
              name: 'Sm Waiting for Assign',
              component: () => import(/* webpackChunkName: "rep" */ '@/views/representatives/Representatives'),
              meta: {
                label: 'Awaiting assignment',
                requiresAuth: true, 
                permission: 'Representatives',
                state: 'list'
              }
            },
            {
              path: '/smart-email/assigned/',
              name: 'Sm Assigned',
              component: () => import(/* webpackChunkName: "rep" */ '@/views/representatives/Representatives'),
              meta: {
                label: 'Assigned',
                requiresAuth: true, 
                permission: 'Representatives',
                state: 'list'
              }
            },
            {
              path: '/smart-email/needs-attention/',
              name: 'Sm Needs Attention',
              component: () => import(/* webpackChunkName: "rep" */ '@/views/representatives/Representatives'),
              meta: {
                label: 'Needs Attention',
                requiresAuth: true, 
                permission: 'Representatives',
                state: 'list'
              }
            },
            {
              path: '/smart-email/awaiting-representative-approval/',
              name: 'Sm Representative Approval',
              component: () => import(/* webpackChunkName: "rep" */ '@/views/representatives/Representatives'),
              meta: {
                label: 'Pending QA',
                requiresAuth: true, 
                permission: 'Representatives',
                state: 'list'
              }
            },
            {
              path: '/smart-email/awaiting-user-approval/',
              name: 'Sm User Approval',
              component: () => import(/* webpackChunkName: "rep" */ '@/views/representatives/Representatives'),
              meta: {
                label: 'Pending approval',
                requiresAuth: true, 
                permission: 'Representatives',
                state: 'list'
              }
            },
            {
              path: '/smart-email/revision-required/',
              name: 'Sm Revision Required',
              component: () => import(/* webpackChunkName: "rep" */ '@/views/representatives/Representatives'),
              meta: {
                label: 'Revision requested',
                requiresAuth: true, 
                permission: 'Representatives',
                state: 'list'
              }
            },
            {
              path: '/smart-email/fulfilled/',
              name: 'Sm Fulfilled',
              component: () => import(/* webpackChunkName: "rep" */ '@/views/representatives/Representatives'),
              meta: {
                label: 'Fulfilled',
                requiresAuth: true, 
                permission: 'Representatives',
                state: 'list'
              }
            },
            {
              path: '/smart-email/:id/',
              name: 'Smart Email Signature Detail',
              component: () => import(/* webpackChunkName: "repDetail" */ '@/views/representatives/SignatureDetail'),
              meta: {
                label: 'Detail',
                requiresAuth: true, 
                permission: 'Representatives',
                state: 'detail'
              }
            },
          ]
        },
        {
          path: 'business-card',
          redirect: '/business-card/waiting-for-assign',
          name: 'Business Card',
          component: {
            render (c) { return c('router-view') }
          },
          children: [
            {
              path: '/business-card/waiting-for-assign/',
              name: 'Bc Waiting for Assign',
              component: () => import(/* webpackChunkName: "rep" */ '@/views/representatives/Representatives'),
              meta: {
                label: 'Awaiting assignment',
                requiresAuth: true, 
                permission: 'Representatives',
                state: 'list'
              }
            },
            {
              path: '/business-card/assigned/',
              name: 'Bc Assigned',
              component: () => import(/* webpackChunkName: "rep" */ '@/views/representatives/Representatives'),
              meta: {
                label: 'Assigned',
                requiresAuth: true, 
                permission: 'Representatives',
                state: 'list'
              }
            },
            {
              path: '/business-card/needs-attention/',
              name: 'Bc Needs Attention',
              component: () => import(/* webpackChunkName: "rep" */ '@/views/representatives/Representatives'),
              meta: {
                label: 'Needs Attention',
                requiresAuth: true, 
                permission: 'Representatives',
                state: 'list'
              }
            },
            {
              path: '/business-card/awaiting-representative-approval/',
              name: 'Bc Representative Approval',
              component: () => import(/* webpackChunkName: "rep" */ '@/views/representatives/Representatives'),
              meta: {
                label: 'Pending QA',
                requiresAuth: true, 
                permission: 'Representatives',
                state: 'list'
              }
            },
            {
              path: '/business-card/awaiting-user-approval/',
              name: 'Bc User Approval',
              component: () => import(/* webpackChunkName: "rep" */ '@/views/representatives/Representatives'),
              meta: {
                label: 'Pending approval',
                requiresAuth: true, 
                permission: 'Representatives',
                state: 'list'
              }
            },
            {
              path: '/business-card/revision-required/',
              name: 'Bc Revision Required',
              component: () => import(/* webpackChunkName: "rep" */ '@/views/representatives/Representatives'),
              meta: {
                label: 'Revision requested',
                requiresAuth: true, 
                permission: 'Representatives',
                state: 'list'
              }
            },
            {
              path: '/business-card/fulfilled/',
              name: 'Bc Fulfilled',
              component: () => import(/* webpackChunkName: "rep" */ '@/views/representatives/Representatives'),
              meta: {
                label: 'Fulfilled',
                requiresAuth: true, 
                permission: 'Representatives',
                state: 'list'
              }
            },
            {
              path: '/business-card/:id/',
              name: 'Business Card Detail',
              component: () => import(/* webpackChunkName: "repDetail" */ '@/views/representatives/SignatureDetail'),
              meta: {
                label: 'Detail',
                requiresAuth: true, 
                permission: 'Representatives',
                state: 'detail'
              }
            },
          ]
        },
        {
          path: 'social-media-kit',
          redirect: '/social-media-kit/waiting-for-assign',
          name: 'Social Media Kit',
          component: {
            render (c) { return c('router-view') }
          },
          children: [
            {
              path: '/social-media-kit/waiting-for-assign/',
              name: 'Smk Waiting for Assign',
              component: () => import(/* webpackChunkName: "rep" */ '@/views/representatives/Representatives'),
              meta: {
                label: 'Awaiting assignment',
                requiresAuth: true, 
                permission: 'Representatives',
                state: 'list'
              }
            },
            {
              path: '/social-media-kit/assigned/',
              name: 'Smk Assigned',
              component: () => import(/* webpackChunkName: "rep" */ '@/views/representatives/Representatives'),
              meta: {
                label: 'Assigned',
                requiresAuth: true, 
                permission: 'Representatives',
                state: 'list'
              }
            },
            {
              path: '/social-media-kit/needs-attention/',
              name: 'Smk Needs Attention',
              component: () => import(/* webpackChunkName: "rep" */ '@/views/representatives/Representatives'),
              meta: {
                label: 'Needs Attention',
                requiresAuth: true, 
                permission: 'Representatives',
                state: 'list'
              }
            },
            {
              path: '/social-media-kit/awaiting-representative-approval/',
              name: 'Smk Representative Approval',
              component: () => import(/* webpackChunkName: "rep" */ '@/views/representatives/Representatives'),
              meta: {
                label: 'Pending QA',
                requiresAuth: true, 
                permission: 'Representatives',
                state: 'list'
              }
            },
            {
              path: '/social-media-kit/awaiting-user-approval/',
              name: 'Smk User Approval',
              component: () => import(/* webpackChunkName: "rep" */ '@/views/representatives/Representatives'),
              meta: {
                label: 'Pending approval',
                requiresAuth: true, 
                permission: 'Representatives',
                state: 'list'
              }
            },
            {
              path: '/social-media-kit/revision-required/',
              name: 'Smk Revision Required',
              component: () => import(/* webpackChunkName: "rep" */ '@/views/representatives/Representatives'),
              meta: {
                label: 'Revision requested',
                requiresAuth: true, 
                permission: 'Representatives',
                state: 'list'
              }
            },
            {
              path: '/social-media-kit/fulfilled/',
              name: 'Smk Fulfilled',
              component: () => import(/* webpackChunkName: "rep" */ '@/views/representatives/Representatives'),
              meta: {
                label: 'Fulfilled',
                requiresAuth: true, 
                permission: 'Representatives',
                state: 'list'
              }
            },
            {
              path: '/social-media-kit/:id/',
              name: 'Social Media Kit Detail',
              component: () => import(/* webpackChunkName: "repDetail" */ '@/views/representatives/SignatureDetail'),
              meta: {
                label: 'Detail',
                requiresAuth: true, 
                permission: 'Representatives',
                state: 'detail'
              }
            },
          ]
        },
        {
          path: 'qr-business-card',
          redirect: '/qr-business-card/waiting-for-assign',
          name: 'QR Business Card',
          component: {
            render (c) { return c('router-view') }
          },
          children: [
            {
              path: '/qr-business-card/waiting-for-assign/',
              name: 'QR Waiting for Assign',
              component: () => import(/* webpackChunkName: "rep" */ '@/views/representatives/Representatives'),
              meta: {
                label: 'Awaiting assignment',
                requiresAuth: true, 
                permission: 'Representatives',
                state: 'list'
              }
            },
            {
              path: '/qr-business-card/assigned/',
              name: 'QR Assigned',
              component: () => import(/* webpackChunkName: "rep" */ '@/views/representatives/Representatives'),
              meta: {
                label: 'Assigned',
                requiresAuth: true, 
                permission: 'Representatives',
                state: 'list'
              }
            },
            {
              path: '/qr-business-card/needs-attention/',
              name: 'QR Needs Attention',
              component: () => import(/* webpackChunkName: "rep" */ '@/views/representatives/Representatives'),
              meta: {
                label: 'Needs Attention',
                requiresAuth: true, 
                permission: 'Representatives',
                state: 'list'
              }
            },
            {
              path: '/qr-business-card/awaiting-representative-approval/',
              name: 'QR Representative Approval',
              component: () => import(/* webpackChunkName: "rep" */ '@/views/representatives/Representatives'),
              meta: {
                label: 'Pending QA',
                requiresAuth: true, 
                permission: 'Representatives',
                state: 'list'
              }
            },
            {
              path: '/qr-business-card/awaiting-user-approval/',
              name: 'QR User Approval',
              component: () => import(/* webpackChunkName: "rep" */ '@/views/representatives/Representatives'),
              meta: {
                label: 'Pending approval',
                requiresAuth: true, 
                permission: 'Representatives',
                state: 'list'
              }
            },
            {
              path: '/qr-business-card/revision-required/',
              name: 'QR Revision Required',
              component: () => import(/* webpackChunkName: "rep" */ '@/views/representatives/Representatives'),
              meta: {
                label: 'Revision requested',
                requiresAuth: true, 
                permission: 'Representatives',
                state: 'list'
              }
            },
            {
              path: '/qr-business-card/fulfilled/',
              name: 'QR Fulfilled',
              component: () => import(/* webpackChunkName: "rep" */ '@/views/representatives/Representatives'),
              meta: {
                label: 'Fulfilled',
                requiresAuth: true, 
                permission: 'Representatives',
                state: 'list'
              }
            },
            {
              path: '/qr-business-card/:id/',
              name: 'QR Business Card Detail',
              component: () => import(/* webpackChunkName: "repDetail" */ '@/views/representatives/SignatureDetail'),
              meta: {
                label: 'Detail',
                requiresAuth: true, 
                permission: 'Representatives',
                state: 'detail'
              }
            },
          ]
        },
        {
          path: 'avatar-me',
          redirect: '/avatar-me/waiting-for-assign',
          name: 'Avatar Me',
          component: {
            render (c) { return c('router-view') }
          },
          children: [
            {
              path: '/avatar-me/waiting-for-assign/',
              name: 'Av Waiting for Assign',
              component: () => import(/* webpackChunkName: "rep" */ '@/views/representatives/Representatives'),
              meta: {
                label: 'Awaiting assignment',
                requiresAuth: true, 
                permission: 'Representatives',
                state: 'list'
              }
            },
            {
              path: '/avatar-me/assigned/',
              name: 'Av Assigned',
              component: () => import(/* webpackChunkName: "rep" */ '@/views/representatives/Representatives'),
              meta: {
                label: 'Assigned',
                requiresAuth: true, 
                permission: 'Representatives',
                state: 'list'
              }
            },
            {
              path: '/avatar-me/needs-attention/',
              name: 'Av Needs Attention',
              component: () => import(/* webpackChunkName: "rep" */ '@/views/representatives/Representatives'),
              meta: {
                label: 'Needs Attention',
                requiresAuth: true, 
                permission: 'Representatives',
                state: 'list'
              }
            },
            {
              path: '/avatar-me/awaiting-representative-approval/',
              name: 'Av Representative Approval',
              component: () => import(/* webpackChunkName: "rep" */ '@/views/representatives/Representatives'),
              meta: {
                label: 'Pending QA',
                requiresAuth: true, 
                permission: 'Representatives',
                state: 'list'
              }
            },
            {
              path: '/avatar-me/awaiting-user-approval/',
              name: 'Av User Approval',
              component: () => import(/* webpackChunkName: "rep" */ '@/views/representatives/Representatives'),
              meta: {
                label: 'Pending approval',
                requiresAuth: true, 
                permission: 'Representatives',
                state: 'list'
              }
            },
            {
              path: '/avatar-me/revision-required/',
              name: 'Av Revision Required',
              component: () => import(/* webpackChunkName: "rep" */ '@/views/representatives/Representatives'),
              meta: {
                label: 'Revision requested',
                requiresAuth: true, 
                permission: 'Representatives',
                state: 'list'
              }
            },
            {
              path: '/avatar-me/fulfilled/',
              name: 'Av Fulfilled',
              component: () => import(/* webpackChunkName: "rep" */ '@/views/representatives/Representatives'),
              meta: {
                label: 'Fulfilled',
                requiresAuth: true, 
                permission: 'Representatives',
                state: 'list'
              }
            },
            {
              path: '/avatar-me/:id/',
              name: 'Avatar Me Detail',
              component: () => import(/* webpackChunkName: "repDetail" */ '@/views/representatives/SignatureDetail'),
              meta: {
                label: 'Detail',
                requiresAuth: true, 
                permission: 'Representatives',
                state: 'detail'
              }
            },
          ]
        },
        {
          path: 'silver-and-gold',
          redirect: '/silver-and-gold/waiting-for-assign',
          name: 'Silver & Gold',
          component: {
            render (c) { return c('router-view') }
          },
          children: [
            {
              path: '/silver-and-gold/waiting-for-assign/',
              name: 'Sg Waiting for Assign',
              component: () => import(/* webpackChunkName: "rep" */ '@/views/representatives/Representatives'),
              meta: {
                label: 'Awaiting assignment',
                requiresAuth: true, 
                permission: 'Representatives',
                state: 'list'
              }
            },
            {
              path: '/silver-and-gold/assigned/',
              name: 'Sg Assigned',
              component: () => import(/* webpackChunkName: "rep" */ '@/views/representatives/Representatives'),
              meta: {
                label: 'Assigned',
                requiresAuth: true, 
                permission: 'Representatives',
                state: 'list'
              }
            },
            {
              path: '/silver-and-gold/needs-attention/',
              name: 'Sg Needs Attention',
              component: () => import(/* webpackChunkName: "rep" */ '@/views/representatives/Representatives'),
              meta: {
                label: 'Needs Attention',
                requiresAuth: true, 
                permission: 'Representatives',
                state: 'list'
              }
            },
            {
              path: '/silver-and-gold/awaiting-representative-approval/',
              name: 'Sg Representative Approval',
              component: () => import(/* webpackChunkName: "rep" */ '@/views/representatives/Representatives'),
              meta: {
                label: 'Pending QA',
                requiresAuth: true, 
                permission: 'Representatives',
                state: 'list'
              }
            },
            {
              path: '/silver-and-gold/awaiting-user-approval/',
              name: 'Sg User Approval',
              component: () => import(/* webpackChunkName: "rep" */ '@/views/representatives/Representatives'),
              meta: {
                label: 'Pending approval',
                requiresAuth: true, 
                permission: 'Representatives',
                state: 'list'
              }
            },
            {
              path: '/silver-and-gold/revision-required/',
              name: 'Sg Revision Required',
              component: () => import(/* webpackChunkName: "rep" */ '@/views/representatives/Representatives'),
              meta: {
                label: 'Revision requested',
                requiresAuth: true, 
                permission: 'Representatives',
                state: 'list'
              }
            },
            {
              path: '/silver-and-gold/fulfilled/',
              name: 'Sg Fulfilled',
              component: () => import(/* webpackChunkName: "rep" */ '@/views/representatives/Representatives'),
              meta: {
                label: 'Fulfilled',
                requiresAuth: true, 
                permission: 'Representatives',
                state: 'list'
              }
            },
            {
              path: '/silver-and-gold/:id/',
              name: 'Silver and Gold Detail',
              component: () => import(/* webpackChunkName: "repDetail" */ '@/views/representatives/SignatureDetail'),
              meta: {
                label: 'Detail',
                requiresAuth: true, 
                permission: 'Representatives',
                state: 'detail'
              }
            },
          ]
        },
        {
          path: 'highlight-covers',
          redirect: '/highlight-covers/waiting-for-assign',
          name: 'Highlight Covers',
          component: {
            render (c) { return c('router-view') }
          },
          children: [
            {
              path: '/highlight-covers/waiting-for-assign/',
              name: 'Hc Waiting for Assign',
              component: () => import(/* webpackChunkName: "rep" */ '@/views/representatives/Representatives'),
              meta: {
                label: 'Awaiting assignment',
                requiresAuth: true, 
                permission: 'Representatives',
                state: 'list'
              }
            },
            {
              path: '/highlight-covers/assigned/',
              name: 'Hc Assigned',
              component: () => import(/* webpackChunkName: "rep" */ '@/views/representatives/Representatives'),
              meta: {
                label: 'Assigned',
                requiresAuth: true, 
                permission: 'Representatives',
                state: 'list'
              }
            },
            {
              path: '/highlight-covers/needs-attention/',
              name: 'Hc Needs Attention',
              component: () => import(/* webpackChunkName: "rep" */ '@/views/representatives/Representatives'),
              meta: {
                label: 'Needs Attention',
                requiresAuth: true, 
                permission: 'Representatives',
                state: 'list'
              }
            },
            {
              path: '/highlight-covers/awaiting-representative-approval/',
              name: 'Hc Representative Approval',
              component: () => import(/* webpackChunkName: "rep" */ '@/views/representatives/Representatives'),
              meta: {
                label: 'Pending QA',
                requiresAuth: true, 
                permission: 'Representatives',
                state: 'list'
              }
            },
            {
              path: '/highlight-covers/awaiting-user-approval/',
              name: 'Hc User Approval',
              component: () => import(/* webpackChunkName: "rep" */ '@/views/representatives/Representatives'),
              meta: {
                label: 'Pending approval',
                requiresAuth: true, 
                permission: 'Representatives',
                state: 'list'
              }
            },
            {
              path: '/highlight-covers/revision-required/',
              name: 'Hc Revision Required',
              component: () => import(/* webpackChunkName: "rep" */ '@/views/representatives/Representatives'),
              meta: {
                label: 'Revision requested',
                requiresAuth: true, 
                permission: 'Representatives',
                state: 'list'
              }
            },
            {
              path: '/highlight-covers/fulfilled/',
              name: 'Hc Fulfilled',
              component: () => import(/* webpackChunkName: "rep" */ '@/views/representatives/Representatives'),
              meta: {
                label: 'Fulfilled',
                requiresAuth: true, 
                permission: 'Representatives',
                state: 'list'
              }
            },
            {
              path: '/highlight-covers/:id/',
              name: 'Highlight Covers Detail',
              component: () => import(/* webpackChunkName: "repDetail" */ '@/views/representatives/SignatureDetail'),
              meta: {
                label: 'Detail',
                requiresAuth: true, 
                permission: 'Representatives',
                state: 'detail'
              }
            },
          ]
        },
        {
          path: 'skins-bundle',
          redirect: '/skins-bundle/waiting-for-assign',
          name: 'Skins Bundle',
          component: {
            render (c) { return c('router-view') }
          },
          children: [
            {
              path: '/skins-bundle/waiting-for-assign/',
              name: 'Sb Waiting for Assign',
              component: () => import(/* webpackChunkName: "rep" */ '@/views/representatives/Representatives'),
              meta: {
                label: 'Awaiting assignment',
                requiresAuth: true, 
                permission: 'Representatives',
                state: 'list'
              }
            },
            {
              path: '/skins-bundle/assigned/',
              name: 'Sb Assigned',
              component: () => import(/* webpackChunkName: "rep" */ '@/views/representatives/Representatives'),
              meta: {
                label: 'Assigned',
                requiresAuth: true, 
                permission: 'Representatives',
                state: 'list'
              }
            },
            {
              path: '/skins-bundle/needs-attention/',
              name: 'Sb Needs Attention',
              component: () => import(/* webpackChunkName: "rep" */ '@/views/representatives/Representatives'),
              meta: {
                label: 'Needs Attention',
                requiresAuth: true, 
                permission: 'Representatives',
                state: 'list'
              }
            },
            {
              path: '/skins-bundle/awaiting-representative-approval/',
              name: 'Sb Representative Approval',
              component: () => import(/* webpackChunkName: "rep" */ '@/views/representatives/Representatives'),
              meta: {
                label: 'Pending QA',
                requiresAuth: true, 
                permission: 'Representatives',
                state: 'list'
              }
            },
            {
              path: '/skins-bundle/awaiting-user-approval/',
              name: 'Sb User Approval',
              component: () => import(/* webpackChunkName: "rep" */ '@/views/representatives/Representatives'),
              meta: {
                label: 'Pending approval',
                requiresAuth: true, 
                permission: 'Representatives',
                state: 'list'
              }
            },
            {
              path: '/skins-bundle/revision-required/',
              name: 'Sb Revision Required',
              component: () => import(/* webpackChunkName: "rep" */ '@/views/representatives/Representatives'),
              meta: {
                label: 'Revision requested',
                requiresAuth: true, 
                permission: 'Representatives',
                state: 'list'
              }
            },
            {
              path: '/skins-bundle/fulfilled/',
              name: 'Sb Fulfilled',
              component: () => import(/* webpackChunkName: "rep" */ '@/views/representatives/Representatives'),
              meta: {
                label: 'Fulfilled',
                requiresAuth: true, 
                permission: 'Representatives',
                state: 'list'
              }
            },
            {
              path: '/skins-bundle/:id/',
              name: 'Skins Bundle Detail',
              component: () => import(/* webpackChunkName: "repDetail" */ '@/views/representatives/SignatureDetail'),
              meta: {
                label: 'Detail',
                requiresAuth: true, 
                permission: 'Representatives',
                state: 'detail'
              }
            },
          ]
        },
        {
          path: 'graphic-addon',
          redirect: '/graphic-addon/waiting-for-assign',
          name: 'Graphic Add-on',
          component: {
            render (c) { return c('router-view') }
          },
          children: [
            {
              path: '/graphic-addon/waiting-for-assign/',
              name: 'Ga Waiting for Assign',
              component: () => import(/* webpackChunkName: "rep" */ '@/views/representatives/Representatives'),
              meta: {
                label: 'Awaiting assignment',
                requiresAuth: true, 
                permission: 'Representatives',
                state: 'list'
              }
            },
            {
              path: '/graphic-addon/assigned/',
              name: 'Ga Assigned',
              component: () => import(/* webpackChunkName: "rep" */ '@/views/representatives/Representatives'),
              meta: {
                label: 'Assigned',
                requiresAuth: true, 
                permission: 'Representatives',
                state: 'list'
              }
            },
            {
              path: '/graphic-addon/needs-attention/',
              name: 'Ga Needs Attention',
              component: () => import(/* webpackChunkName: "rep" */ '@/views/representatives/Representatives'),
              meta: {
                label: 'Needs Attention',
                requiresAuth: true, 
                permission: 'Representatives',
                state: 'list'
              }
            },
            {
              path: '/graphic-addon/awaiting-representative-approval/',
              name: 'Ga Representative Approval',
              component: () => import(/* webpackChunkName: "rep" */ '@/views/representatives/Representatives'),
              meta: {
                label: 'Pending QA',
                requiresAuth: true, 
                permission: 'Representatives',
                state: 'list'
              }
            },
            {
              path: '/graphic-addon/awaiting-user-approval/',
              name: 'Ga User Approval',
              component: () => import(/* webpackChunkName: "rep" */ '@/views/representatives/Representatives'),
              meta: {
                label: 'Pending approval',
                requiresAuth: true, 
                permission: 'Representatives',
                state: 'list'
              }
            },
            {
              path: '/graphic-addon/revision-required/',
              name: 'Ga Revision Required',
              component: () => import(/* webpackChunkName: "rep" */ '@/views/representatives/Representatives'),
              meta: {
                label: 'Revision requested',
                requiresAuth: true, 
                permission: 'Representatives',
                state: 'list'
              }
            },
            {
              path: '/graphic-addon/fulfilled/',
              name: 'Ga Fulfilled',
              component: () => import(/* webpackChunkName: "rep" */ '@/views/representatives/Representatives'),
              meta: {
                label: 'Fulfilled',
                requiresAuth: true, 
                permission: 'Representatives',
                state: 'list'
              }
            },
            {
              path: '/graphic-addon/:id/',
              name: 'Graphic Add-on Detail',
              component: () => import(/* webpackChunkName: "repDetail" */ '@/views/representatives/SignatureDetail'),
              meta: {
                label: 'Detail',
                requiresAuth: true, 
                permission: 'Representatives',
                state: 'detail'
              }
            },
          ]
        },
        {
          path: 'signature-brand',
          redirect: '/signature-brand/waiting-for-assign',
          name: 'Signature Brand',
          component: {
            render (c) { return c('router-view') }
          },
          children: [
            {
              path: '/signature-brand/waiting-for-assign/',
              name: 'Sgb Waiting for Assign',
              component: () => import(/* webpackChunkName: "rep" */ '@/views/representatives/Representatives'),
              meta: {
                label: 'Awaiting assignment',
                requiresAuth: true, 
                permission: 'Representatives',
                state: 'list'
              }
            },
            {
              path: '/signature-brand/assigned/',
              name: 'Sgb Assigned',
              component: () => import(/* webpackChunkName: "rep" */ '@/views/representatives/Representatives'),
              meta: {
                label: 'Assigned',
                requiresAuth: true, 
                permission: 'Representatives',
                state: 'list'
              }
            },
            {
              path: '/signature-brand/needs-attention/',
              name: 'Sgb Needs Attention',
              component: () => import(/* webpackChunkName: "rep" */ '@/views/representatives/Representatives'),
              meta: {
                label: 'Needs Attention',
                requiresAuth: true, 
                permission: 'Representatives',
                state: 'list'
              }
            },
            {
              path: '/signature-brand/awaiting-representative-approval/',
              name: 'Sgb Representative Approval',
              component: () => import(/* webpackChunkName: "rep" */ '@/views/representatives/Representatives'),
              meta: {
                label: 'Pending QA',
                requiresAuth: true, 
                permission: 'Representatives',
                state: 'list'
              }
            },
            {
              path: '/signature-brand/awaiting-user-approval/',
              name: 'Sgb User Approval',
              component: () => import(/* webpackChunkName: "rep" */ '@/views/representatives/Representatives'),
              meta: {
                label: 'Pending approval',
                requiresAuth: true, 
                permission: 'Representatives',
                state: 'list'
              }
            },
            {
              path: '/signature-brand/revision-required/',
              name: 'Sgb Revision Required',
              component: () => import(/* webpackChunkName: "rep" */ '@/views/representatives/Representatives'),
              meta: {
                label: 'Revision requested',
                requiresAuth: true, 
                permission: 'Representatives',
                state: 'list'
              }
            },
            {
              path: '/signature-brand/fulfilled/',
              name: 'Sgb Fulfilled',
              component: () => import(/* webpackChunkName: "rep" */ '@/views/representatives/Representatives'),
              meta: {
                label: 'Fulfilled',
                requiresAuth: true, 
                permission: 'Representatives',
                state: 'list'
              }
            },
            {
              path: '/signature-brand/:id/',
              name: 'Signature Brand Detail',
              component: () => import(/* webpackChunkName: "repDetail" */ '@/views/representatives/SignatureDetail'),
              meta: {
                label: 'Detail',
                requiresAuth: true, 
                permission: 'Representatives',
                state: 'detail'
              }
            },
          ]
        },
        {
          path: 'signature-brand-kit',
          redirect: '/signature-brand-kit/waiting-for-assign',
          name: 'Signature Brand Kit',
          component: {
            render (c) { return c('router-view') }
          },
          children: [
            {
              path: '/signature-brand-kit/waiting-for-assign/',
              name: 'SBK Waiting for Assign',
              component: () => import(/* webpackChunkName: "rep" */ '@/views/representatives/Representatives'),
              meta: {
                label: 'Awaiting assignment',
                requiresAuth: true, 
                permission: 'Representatives',
                state: 'list'
              }
            },
            {
              path: '/signature-brand-kit/assigned/',
              name: 'SBK Assigned',
              component: () => import(/* webpackChunkName: "rep" */ '@/views/representatives/Representatives'),
              meta: {
                label: 'Assigned',
                requiresAuth: true, 
                permission: 'Representatives',
                state: 'list'
              }
            },
            {
              path: '/signature-brand-kit/needs-attention/',
              name: 'SBK Needs Attention',
              component: () => import(/* webpackChunkName: "rep" */ '@/views/representatives/Representatives'),
              meta: {
                label: 'Needs Attention',
                requiresAuth: true, 
                permission: 'Representatives',
                state: 'list'
              }
            },
            {
              path: '/signature-brand-kit/awaiting-representative-approval/',
              name: 'SBK Representative Approval',
              component: () => import(/* webpackChunkName: "rep" */ '@/views/representatives/Representatives'),
              meta: {
                label: 'Pending QA',
                requiresAuth: true, 
                permission: 'Representatives',
                state: 'list'
              }
            },
            {
              path: '/signature-brand-kit/awaiting-user-approval/',
              name: 'SBK User Approval',
              component: () => import(/* webpackChunkName: "rep" */ '@/views/representatives/Representatives'),
              meta: {
                label: 'Pending approval',
                requiresAuth: true, 
                permission: 'Representatives',
                state: 'list'
              }
            },
            {
              path: '/signature-brand-kit/revision-required/',
              name: 'SBK Revision Required',
              component: () => import(/* webpackChunkName: "rep" */ '@/views/representatives/Representatives'),
              meta: {
                label: 'Revision requested',
                requiresAuth: true, 
                permission: 'Representatives',
                state: 'list'
              }
            },
            {
              path: '/signature-brand-kit/fulfilled/',
              name: 'SBK Fulfilled',
              component: () => import(/* webpackChunkName: "rep" */ '@/views/representatives/Representatives'),
              meta: {
                label: 'Fulfilled',
                requiresAuth: true, 
                permission: 'Representatives',
                state: 'list'
              }
            },
            {
              path: '/signature-brand-kit/:id/',
              name: 'Signature Brand Kit Detail',
              component: () => import(/* webpackChunkName: "repDetail" */ '@/views/representatives/SignatureDetail'),
              meta: {
                label: 'Detail',
                requiresAuth: true, 
                permission: 'Representatives',
                state: 'detail'
              }
            },
          ]
        },
        {
          path: 'brand-seal',
          redirect: '/brand-seal/waiting-for-assign',
          name: 'Brand Seal',
          component: {
            render (c) { return c('router-view') }
          },
          children: [
            {
              path: '/brand-seal/waiting-for-assign/',
              name: 'BRS Waiting for Assign',
              component: () => import(/* webpackChunkName: "rep" */ '@/views/representatives/Representatives'),
              meta: {
                label: 'Awaiting assignment',
                requiresAuth: true, 
                permission: 'Representatives',
                state: 'list'
              }
            },
            {
              path: '/brand-seal/assigned/',
              name: 'BRS Assigned',
              component: () => import(/* webpackChunkName: "rep" */ '@/views/representatives/Representatives'),
              meta: {
                label: 'Assigned',
                requiresAuth: true, 
                permission: 'Representatives',
                state: 'list'
              }
            },
            {
              path: '/brand-seal/needs-attention/',
              name: 'BRS Needs Attention',
              component: () => import(/* webpackChunkName: "rep" */ '@/views/representatives/Representatives'),
              meta: {
                label: 'Needs Attention',
                requiresAuth: true, 
                permission: 'Representatives',
                state: 'list'
              }
            },
            {
              path: '/brand-seal/awaiting-representative-approval/',
              name: 'BRS Representative Approval',
              component: () => import(/* webpackChunkName: "rep" */ '@/views/representatives/Representatives'),
              meta: {
                label: 'Pending QA',
                requiresAuth: true, 
                permission: 'Representatives',
                state: 'list'
              }
            },
            {
              path: '/brand-seal/awaiting-user-approval/',
              name: 'BRS User Approval',
              component: () => import(/* webpackChunkName: "rep" */ '@/views/representatives/Representatives'),
              meta: {
                label: 'Pending approval',
                requiresAuth: true, 
                permission: 'Representatives',
                state: 'list'
              }
            },
            {
              path: '/brand-seal/revision-required/',
              name: 'BRS Revision Required',
              component: () => import(/* webpackChunkName: "rep" */ '@/views/representatives/Representatives'),
              meta: {
                label: 'Revision requested',
                requiresAuth: true, 
                permission: 'Representatives',
                state: 'list'
              }
            },
            {
              path: '/brand-seal/fulfilled/',
              name: 'BRS Fulfilled',
              component: () => import(/* webpackChunkName: "rep" */ '@/views/representatives/Representatives'),
              meta: {
                label: 'Fulfilled',
                requiresAuth: true, 
                permission: 'Representatives',
                state: 'list'
              }
            },
            {
              path: '/brand-seal/:id/',
              name: 'Brand Seal Detail',
              component: () => import(/* webpackChunkName: "repDetail" */ '@/views/representatives/SignatureDetail'),
              meta: {
                label: 'Detail',
                requiresAuth: true, 
                permission: 'Representatives',
                state: 'detail'
              }
            },
          ]
        },
        {
          path: 'signature-tutorial',
          redirect: '/signature-tutorial/waiting-for-assign',
          name: 'Signature Tutorial',
          component: {
            render (c) { return c('router-view') }
          },
          children: [
            {
              path: '/signature-tutorial/waiting-for-assign/',
              name: 'Sgt Waiting for Assign',
              component: () => import(/* webpackChunkName: "rep" */ '@/views/representatives/Representatives'),
              meta: {
                label: 'Awaiting assignment',
                requiresAuth: true, 
                permission: 'Representatives',
                state: 'list'
              }
            },
            {
              path: '/signature-tutorial/assigned/',
              name: 'Sgt Assigned',
              component: () => import(/* webpackChunkName: "rep" */ '@/views/representatives/Representatives'),
              meta: {
                label: 'Assigned',
                requiresAuth: true, 
                permission: 'Representatives',
                state: 'list'
              }
            },
            {
              path: '/signature-tutorial/needs-attention/',
              name: 'Sgt Needs Attention',
              component: () => import(/* webpackChunkName: "rep" */ '@/views/representatives/Representatives'),
              meta: {
                label: 'Needs Attention',
                requiresAuth: true, 
                permission: 'Representatives',
                state: 'list'
              }
            },
            {
              path: '/signature-tutorial/awaiting-representative-approval/',
              name: 'Sgt Representative Approval',
              component: () => import(/* webpackChunkName: "rep" */ '@/views/representatives/Representatives'),
              meta: {
                label: 'Pending QA',
                requiresAuth: true, 
                permission: 'Representatives',
                state: 'list'
              }
            },
            {
              path: '/signature-tutorial/awaiting-user-approval/',
              name: 'Sgt User Approval',
              component: () => import(/* webpackChunkName: "rep" */ '@/views/representatives/Representatives'),
              meta: {
                label: 'Pending approval',
                requiresAuth: true, 
                permission: 'Representatives',
                state: 'list'
              }
            },
            {
              path: '/signature-tutorial/revision-required/',
              name: 'Sgt Revision Required',
              component: () => import(/* webpackChunkName: "rep" */ '@/views/representatives/Representatives'),
              meta: {
                label: 'Revision requested',
                requiresAuth: true, 
                permission: 'Representatives',
                state: 'list'
              }
            },
            {
              path: '/signature-tutorial/fulfilled/',
              name: 'Sgt Fulfilled',
              component: () => import(/* webpackChunkName: "rep" */ '@/views/representatives/Representatives'),
              meta: {
                label: 'Fulfilled',
                requiresAuth: true, 
                permission: 'Representatives',
                state: 'list'
              }
            },
            {
              path: '/signature-tutorial/:id/',
              name: 'Signature Tutorial Detail',
              component: () => import(/* webpackChunkName: "repDetail" */ '@/views/representatives/SignatureDetail'),
              meta: {
                label: 'Detail',
                requiresAuth: true, 
                permission: 'Representatives',
                state: 'detail'
              }
            },
          ]
        },
        {
          path: 'signature-practice-pdf',
          redirect: '/signature-practice-pdf/waiting-for-assign',
          name: 'Signature Practice',
          component: {
            render (c) { return c('router-view') }
          },
          children: [
            {
              path: '/signature-practice-pdf/waiting-for-assign/',
              name: 'Spf Waiting for Assign',
              component: () => import(/* webpackChunkName: "rep" */ '@/views/representatives/Representatives'),
              meta: {
                label: 'Awaiting assignment',
                requiresAuth: true, 
                permission: 'Representatives',
                state: 'list'
              }
            },
            {
              path: '/signature-practice-pdf/assigned/',
              name: 'Spf Assigned',
              component: () => import(/* webpackChunkName: "rep" */ '@/views/representatives/Representatives'),
              meta: {
                label: 'Assigned',
                requiresAuth: true, 
                permission: 'Representatives',
                state: 'list'
              }
            },
            {
              path: '/signature-practice-pdf/needs-attention/',
              name: 'Spf Needs Attention',
              component: () => import(/* webpackChunkName: "rep" */ '@/views/representatives/Representatives'),
              meta: {
                label: 'Needs Attention',
                requiresAuth: true, 
                permission: 'Representatives',
                state: 'list'
              }
            },
            {
              path: '/signature-practice-pdf/awaiting-representative-approval/',
              name: 'Spf Representative Approval',
              component: () => import(/* webpackChunkName: "rep" */ '@/views/representatives/Representatives'),
              meta: {
                label: 'Pending QA',
                requiresAuth: true, 
                permission: 'Representatives',
                state: 'list'
              }
            },
            {
              path: '/signature-practice-pdf/awaiting-user-approval/',
              name: 'Spf User Approval',
              component: () => import(/* webpackChunkName: "rep" */ '@/views/representatives/Representatives'),
              meta: {
                label: 'Pending approval',
                requiresAuth: true, 
                permission: 'Representatives',
                state: 'list'
              }
            },
            {
              path: '/signature-practice-pdf/revision-required/',
              name: 'Spf Revision Required',
              component: () => import(/* webpackChunkName: "rep" */ '@/views/representatives/Representatives'),
              meta: {
                label: 'Revision requested',
                requiresAuth: true, 
                permission: 'Representatives',
                state: 'list'
              }
            },
            {
              path: '/signature-practice-pdf/fulfilled/',
              name: 'Spf Fulfilled',
              component: () => import(/* webpackChunkName: "rep" */ '@/views/representatives/Representatives'),
              meta: {
                label: 'Fulfilled',
                requiresAuth: true, 
                permission: 'Representatives',
                state: 'list'
              }
            },
            {
              path: '/signature-practice-pdf/:id/',
              name: 'Signature Practice PDF Detail',
              component: () => import(/* webpackChunkName: "repDetail" */ '@/views/representatives/SignatureDetail'),
              meta: {
                label: 'Detail',
                requiresAuth: true, 
                permission: 'Representatives',
                state: 'detail'
              }
            },
          ]
        },
        {
          path: 'signature-variations-trio',
          redirect: '/signature-variations-trio/waiting-for-assign',
          name: 'Signature Variations Trio',
          component: {
            render (c) { return c('router-view') }
          },
          children: [
            {
              path: '/signature-variations-trio/waiting-for-assign/',
              name: 'SVT Waiting for Assign',
              component: () => import(/* webpackChunkName: "rep" */ '@/views/representatives/Representatives'),
              meta: {
                label: 'Awaiting assignment',
                requiresAuth: true, 
                permission: 'Representatives',
                state: 'list'
              }
            },
            {
              path: '/signature-variations-trio/assigned/',
              name: 'SVT Assigned',
              component: () => import(/* webpackChunkName: "rep" */ '@/views/representatives/Representatives'),
              meta: {
                label: 'Assigned',
                requiresAuth: true, 
                permission: 'Representatives',
                state: 'list'
              }
            },
            {
              path: '/signature-variations-trio/needs-attention/',
              name: 'SVT Needs Attention',
              component: () => import(/* webpackChunkName: "rep" */ '@/views/representatives/Representatives'),
              meta: {
                label: 'Needs Attention',
                requiresAuth: true, 
                permission: 'Representatives',
                state: 'list'
              }
            },
            {
              path: '/signature-variations-trio/awaiting-representative-approval/',
              name: 'SVT Representative Approval',
              component: () => import(/* webpackChunkName: "rep" */ '@/views/representatives/Representatives'),
              meta: {
                label: 'Pending QA',
                requiresAuth: true, 
                permission: 'Representatives',
                state: 'list'
              }
            },
            {
              path: '/signature-variations-trio/awaiting-user-approval/',
              name: 'SVT User Approval',
              component: () => import(/* webpackChunkName: "rep" */ '@/views/representatives/Representatives'),
              meta: {
                label: 'Pending approval',
                requiresAuth: true, 
                permission: 'Representatives',
                state: 'list'
              }
            },
            {
              path: '/signature-variations-trio/revision-required/',
              name: 'SVT Revision Required',
              component: () => import(/* webpackChunkName: "rep" */ '@/views/representatives/Representatives'),
              meta: {
                label: 'Revision requested',
                requiresAuth: true, 
                permission: 'Representatives',
                state: 'list'
              }
            },
            {
              path: '/signature-variations-trio/fulfilled/',
              name: 'SVT Fulfilled',
              component: () => import(/* webpackChunkName: "rep" */ '@/views/representatives/Representatives'),
              meta: {
                label: 'Fulfilled',
                requiresAuth: true, 
                permission: 'Representatives',
                state: 'list'
              }
            },
            {
              path: '/signature-variations-trio/:id/',
              name: 'Signature Variations Trio Detail',
              component: () => import(/* webpackChunkName: "repDetail" */ '@/views/representatives/SignatureDetail'),
              meta: {
                label: 'Detail',
                requiresAuth: true, 
                permission: 'Representatives',
                state: 'detail'
              }
            },
          ]
        },
        {
          path: 'kinetic',
          redirect: '/kinetic/waiting-for-assign',
          name: 'Kinetic',
          component: {
            render (c) { return c('router-view') }
          },
          children: [
            {
              path: '/kinetic/waiting-for-assign/',
              name: 'Ki Waiting for Assign',
              component: () => import(/* webpackChunkName: "rep" */ '@/views/representatives/Representatives'),
              meta: {
                label: 'Awaiting assignment',
                requiresAuth: true, 
                permission: 'Representatives',
                state: 'list'
              }
            },
            {
              path: '/kinetic/assigned/',
              name: 'Ki Assigned',
              component: () => import(/* webpackChunkName: "rep" */ '@/views/representatives/Representatives'),
              meta: {
                label: 'Assigned',
                requiresAuth: true, 
                permission: 'Representatives',
                state: 'list'
              }
            },
            {
              path: '/kinetic/needs-attention/',
              name: 'Ki Needs Attention',
              component: () => import(/* webpackChunkName: "rep" */ '@/views/representatives/Representatives'),
              meta: {
                label: 'Needs Attention',
                requiresAuth: true, 
                permission: 'Representatives',
                state: 'list'
              }
            },
            {
              path: '/kinetic/awaiting-representative-approval/',
              name: 'Ki Representative Approval',
              component: () => import(/* webpackChunkName: "rep" */ '@/views/representatives/Representatives'),
              meta: {
                label: 'Pending QA',
                requiresAuth: true, 
                permission: 'Representatives',
                state: 'list'
              }
            },
            {
              path: '/kinetic/awaiting-user-approval/',
              name: 'Ki User Approval',
              component: () => import(/* webpackChunkName: "rep" */ '@/views/representatives/Representatives'),
              meta: {
                label: 'Pending approval',
                requiresAuth: true, 
                permission: 'Representatives',
                state: 'list'
              }
            },
            {
              path: '/kinetic/revision-required/',
              name: 'Ki Revision Required',
              component: () => import(/* webpackChunkName: "rep" */ '@/views/representatives/Representatives'),
              meta: {
                label: 'Revision requested',
                requiresAuth: true, 
                permission: 'Representatives',
                state: 'list'
              }
            },
            {
              path: '/kinetic/fulfilled/',
              name: 'Ki Fulfilled',
              component: () => import(/* webpackChunkName: "rep" */ '@/views/representatives/Representatives'),
              meta: {
                label: 'Fulfilled',
                requiresAuth: true, 
                permission: 'Representatives',
                state: 'list'
              }
            },
            {
              path: '/kinetic/:id/',
              name: 'Kinetic Detail',
              component: () => import(/* webpackChunkName: "repDetail" */ '@/views/representatives/SignatureDetail'),
              meta: {
                label: 'Detail',
                requiresAuth: true, 
                permission: 'Representatives',
                state: 'detail'
              }
            },
          ]
        },
        {
          path: 'electric',
          redirect: '/electric/waiting-for-assign',
          name: 'Electric',
          component: {
            render (c) { return c('router-view') }
          },
          children: [
            {
              path: '/electric/waiting-for-assign/',
              name: 'Elc Waiting for Assign',
              component: () => import(/* webpackChunkName: "rep" */ '@/views/representatives/Representatives'),
              meta: {
                label: 'Awaiting assignment',
                requiresAuth: true, 
                permission: 'Representatives',
                state: 'list'
              }
            },
            {
              path: '/electric/assigned/',
              name: 'Elc Assigned',
              component: () => import(/* webpackChunkName: "rep" */ '@/views/representatives/Representatives'),
              meta: {
                label: 'Assigned',
                requiresAuth: true, 
                permission: 'Representatives',
                state: 'list'
              }
            },
            {
              path: '/electric/needs-attention/',
              name: 'Elc Needs Attention',
              component: () => import(/* webpackChunkName: "rep" */ '@/views/representatives/Representatives'),
              meta: {
                label: 'Needs Attention',
                requiresAuth: true, 
                permission: 'Representatives',
                state: 'list'
              }
            },
            {
              path: '/electric/awaiting-representative-approval/',
              name: 'Elc Representative Approval',
              component: () => import(/* webpackChunkName: "rep" */ '@/views/representatives/Representatives'),
              meta: {
                label: 'Pending QA',
                requiresAuth: true, 
                permission: 'Representatives',
                state: 'list'
              }
            },
            {
              path: '/electric/awaiting-user-approval/',
              name: 'Elc User Approval',
              component: () => import(/* webpackChunkName: "rep" */ '@/views/representatives/Representatives'),
              meta: {
                label: 'Pending approval',
                requiresAuth: true, 
                permission: 'Representatives',
                state: 'list'
              }
            },
            {
              path: '/electric/revision-required/',
              name: 'Elc Revision Required',
              component: () => import(/* webpackChunkName: "rep" */ '@/views/representatives/Representatives'),
              meta: {
                label: 'Revision requested',
                requiresAuth: true, 
                permission: 'Representatives',
                state: 'list'
              }
            },
            {
              path: '/electric/fulfilled/',
              name: 'Elc Fulfilled',
              component: () => import(/* webpackChunkName: "rep" */ '@/views/representatives/Representatives'),
              meta: {
                label: 'Fulfilled',
                requiresAuth: true, 
                permission: 'Representatives',
                state: 'list'
              }
            },
            {
              path: '/electric/:id/',
              name: 'Electric Detail',
              component: () => import(/* webpackChunkName: "repDetail" */ '@/views/representatives/SignatureDetail'),
              meta: {
                label: 'Detail',
                requiresAuth: true, 
                permission: 'Representatives',
                state: 'detail'
              }
            },
          ]
        },
        {
          path: 'photologos',
          redirect: '/photologos/pending',
          name: 'Photologos',
          component: {
            render (c) { return c('router-view') }
          },
          children: [
            {
              path: '/photologos/pending/',
              name: 'D Pending ',
              component: () => import(/* webpackChunkName: "designer" */ '@/views/designers/Designers'),
              meta: {
                label: 'Pending',
                requiresAuth: true, 
                permission: 'Designers',
                state: 'list'
              }
            },
            {
              path: '/photologos/completed/',
              name: 'D Completed',
              component: () => import(/* webpackChunkName: "designer" */ '@/views/designers/Designers'),
              meta: {
                label: 'Completed',
                requiresAuth: true, 
                permission: 'Designers',
                state: 'list'
              }
            },
            {
              path: '/photologos/:versionId/',
              name: 'D Detail',
              component: () => import(/* webpackChunkName: "designerDetail" */ '@/views/designers/DesignerDetail'),
              props: { versionId: true},
              meta: {
                label: 'Detail',
                requiresAuth: true, 
                permission: 'Designers',
                state: 'detail'
              }
            },
          ]
        },
        {
          path: 'lovemarks',
          redirect: '/lovemarks/pending',
          name: 'Lovemarks',
          component: {
            render (c) { return c('router-view') }
          },
          children: [
            {
              path: '/lovemarks/pending/',
              name: 'L Pending ',
              component: () => import(/* webpackChunkName: "designer" */ '@/views/designers/Designers'),
              meta: {
                label: 'Pending',
                requiresAuth: true, 
                permission: 'Designers',
                state: 'list'
              }
            },
            {
              path: '/lovemarks/completed/',
              name: 'L Completed',
              component: () => import(/* webpackChunkName: "designer" */ '@/views/designers/Designers'),
              meta: {
                label: 'Completed',
                requiresAuth: true, 
                permission: 'Designers',
                state: 'list'
              }
            },
            {
              path: '/lovemarks/:versionId/',
              name: 'L Detail',
              component: () => import(/* webpackChunkName: "designerDetail" */ '@/views/designers/DesignerDetail'),
              props: { versionId: true},
              meta: {
                label: 'Detail',
                requiresAuth: true, 
                permission: 'Designers',
                state: 'detail'
              }
            },
          ]
        },
        {
          path: 'pl-iconic',
          redirect: '/pl-iconic/pending',
          name: 'Iconic Photologo D',
          component: {
            render (c) { return c('router-view') }
          },
          children: [
            {
              path: '/pl-iconic/pending/',
              name: 'IC Pending ',
              component: () => import(/* webpackChunkName: "designer" */ '@/views/designers/Designers'),
              meta: {
                label: 'Pending',
                requiresAuth: true, 
                permission: 'Designers',
                state: 'list'
              }
            },
            {
              path: '/pl-iconic/completed/',
              name: 'IC Completed',
              component: () => import(/* webpackChunkName: "designer" */ '@/views/designers/Designers'),
              meta: {
                label: 'Completed',
                requiresAuth: true, 
                permission: 'Designers',
                state: 'list'
              }
            },
            {
              path: '/pl-iconic/:versionId/',
              name: 'IC Detail',
              component: () => import(/* webpackChunkName: "designerDetail" */ '@/views/designers/DesignerDetail'),
              props: { versionId: true},
              meta: {
                label: 'Detail',
                requiresAuth: true, 
                permission: 'Designers',
                state: 'detail'
              }
            },
          ]
        },
        {
          path: 'photologos-signature-brand',
          redirect: '/photologos-signature-brand/pending',
          name: 'PL Signature Brand',
          component: {
            render (c) { return c('router-view') }
          },
          children: [
            {
              path: '/photologos-signature-brand/pending/',
              name: 'PhSB Pending ',
              component: () => import(/* webpackChunkName: "designer" */ '@/views/designers/Designers'),
              meta: {
                label: 'Pending',
                requiresAuth: true, 
                permission: 'Designers',
                state: 'list'
              }
            },
            {
              path: '/photologos-signature-brand/completed/',
              name: 'PhSB Completed',
              component: () => import(/* webpackChunkName: "designer" */ '@/views/designers/Designers'),
              meta: {
                label: 'Completed',
                requiresAuth: true, 
                permission: 'Designers',
                state: 'list'
              }
            },
            {
              path: '/photologos-signature-brand/:versionId/',
              name: 'PhSB Detail',
              component: () => import(/* webpackChunkName: "designerDetail" */ '@/views/designers/DesignerDetail'),
              props: { versionId: true},
              meta: {
                label: 'Detail',
                requiresAuth: true, 
                permission: 'Designers',
                state: 'detail'
              }
            },
          ]
        },
        {
          path: 'pl-initials-pack',
          redirect: '/pl-initials-pack/pending',
          name: 'Initials Pack D',
          component: {
            render (c) { return c('router-view') }
          },
          children: [
            {
              path: '/pl-initials-pack/pending/',
              name: 'INP Pending',
              component: () => import(/* webpackChunkName: "designer" */ '@/views/designers/Designers'),
              meta: {
                label: 'Pending',
                requiresAuth: true, 
                permission: 'Designers',
                state: 'list'
              }
            },
            {
              path: '/pl-initials-pack/completed/',
              name: 'INP Completed',
              component: () => import(/* webpackChunkName: "designer" */ '@/views/designers/Designers'),
              meta: {
                label: 'Completed',
                requiresAuth: true, 
                permission: 'Designers',
                state: 'list'
              }
            },
            {
              path: '/pl-initials-pack/:versionId/',
              name: 'INP Detail',
              component: () => import(/* webpackChunkName: "designerDetail" */ '@/views/designers/DesignerDetail'),
              props: { versionId: true},
              meta: {
                label: 'Detail',
                requiresAuth: true, 
                permission: 'Designers',
                state: 'detail'
              }
            },
          ]
        },
        {
          path: 'pl-brand-seal-pack',
          redirect: '/pl-brand-seal-pack/pending',
          name: 'Brand Seal Pack D',
          component: {
            render (c) { return c('router-view') }
          },
          children: [
            {
              path: '/pl-brand-seal-pack/pending/',
              name: 'BSP Pending',
              component: () => import(/* webpackChunkName: "designer" */ '@/views/designers/Designers'),
              meta: {
                label: 'Pending',
                requiresAuth: true, 
                permission: 'Designers',
                state: 'list'
              }
            },
            {
              path: '/pl-brand-seal-pack/completed/',
              name: 'BSP Completed',
              component: () => import(/* webpackChunkName: "designer" */ '@/views/designers/Designers'),
              meta: {
                label: 'Completed',
                requiresAuth: true, 
                permission: 'Designers',
                state: 'list'
              }
            },
            {
              path: '/pl-brand-seal-pack/:versionId/',
              name: 'BSP Detail',
              component: () => import(/* webpackChunkName: "designerDetail" */ '@/views/designers/DesignerDetail'),
              props: { versionId: true},
              meta: {
                label: 'Detail',
                requiresAuth: true, 
                permission: 'Designers',
                state: 'detail'
              }
            },
          ]
        },
        {
          path: 'ultimate-signature',
          redirect: '/ultimate-signature/pending',
          name: 'Ultimate Signature Kit D',
          meta: {
            label: 'Ultimate Signature Kit',
          },
          component: {
            render (c) { return c('router-view') }
          },
          children: [
            {
              path: '/ultimate-signature/pending/',
              name: 'USK Pending ',
              component: () => import(/* webpackChunkName: "designer" */ '@/views/designers/Designers'),
              meta: {
                label: 'Pending',
                requiresAuth: true, 
                permission: 'Designers',
                state: 'list'
              }
            },
            {
              path: '/ultimate-signature/completed/',
              name: 'USK Completed',
              component: () => import(/* webpackChunkName: "designer" */ '@/views/designers/Designers'),
              meta: {
                label: 'Completed',
                requiresAuth: true, 
                permission: 'Designers',
                state: 'list'
              }
            },
            {
              path: '/ultimate-signature/:versionId/',
              name: 'USK Detail',
              component: () => import(/* webpackChunkName: "designerDetail" */ '@/views/designers/DesignerDetail'),
              props: { versionId: true},
              meta: {
                label: 'Detail',
                requiresAuth: true, 
                permission: 'Designers',
                state: 'detail'
              }
            },
          ]
        },
        {
          path: 'initials',
          redirect: '/initials/pending',
          name: 'Initials D',
          meta: {
            label: 'Initials Edition',
          },
          component: {
            render (c) { return c('router-view') }
          },
          children: [
            {
              path: '/initials/pending/',
              name: 'I Pending ',
              component: () => import(/* webpackChunkName: "designer" */ '@/views/designers/Designers'),
              meta: {
                label: 'Pending',
                requiresAuth: true, 
                permission: 'Designers',
                state: 'list'
              }
            },
            {
              path: '/initials/completed/',
              name: 'I Completed',
              component: () => import(/* webpackChunkName: "designer" */ '@/views/designers/Designers'),
              meta: {
                label: 'Completed',
                requiresAuth: true, 
                permission: 'Designers',
                state: 'list'
              }
            },
            {
              path: '/initials/:versionId/',
              name: 'I Detail',
              component: () => import(/* webpackChunkName: "designerDetail" */ '@/views/designers/DesignerDetail'),
              props: { versionId: true},
              meta: {
                label: 'Detail',
                requiresAuth: true, 
                permission: 'Designers',
                state: 'detail'
              }
            },
          ]
        },
        {
          path: 'extension',
          redirect: '/extension/pending',
          name: 'Extension D',
          meta: {
            label: 'Extension Kit',
          },
          component: {
            render (c) { return c('router-view') }
          },
          children: [
            {
              path: '/extension/pending/',
              name: 'E Pending ',
              component: () => import(/* webpackChunkName: "designer" */ '@/views/designers/Designers'),
              meta: {
                label: 'Pending',
                requiresAuth: true, 
                permission: 'Designers',
                state: 'list'
              }
            },
            {
              path: '/extension/completed/',
              name: 'E Completed',
              component: () => import(/* webpackChunkName: "designer" */ '@/views/designers/Designers'),
              meta: {
                label: 'Completed',
                requiresAuth: true, 
                permission: 'Designers',
                state: 'list'
              }
            },
            {
              path: '/extension/:versionId/',
              name: 'E Detail',
              component: () => import(/* webpackChunkName: "designerDetail" */ '@/views/designers/DesignerDetail'),
              props: { versionId: true},
              meta: {
                label: 'Detail',
                requiresAuth: true, 
                permission: 'Designers',
                state: 'detail'
              }
            },
          ]
        },
        {
          path: 'metallic',
          redirect: '/metallic/pending',
          name: 'Metallic D',
          meta: {
            label: 'Metallic Edition',
          },
          component: {
            render (c) { return c('router-view') }
          },
          children: [
            {
              path: '/metallic/pending/',
              name: 'M Pending ',
              component: () => import(/* webpackChunkName: "designer" */ '@/views/designers/Designers'),
              meta: {
                label: 'Pending',
                requiresAuth: true, 
                permission: 'Designers',
                state: 'list'
              }
            },
            {
              path: '/metallic/completed/',
              name: 'M Completed',
              component: () => import(/* webpackChunkName: "designer" */ '@/views/designers/Designers'),
              meta: {
                label: 'Completed',
                requiresAuth: true, 
                permission: 'Designers',
                state: 'list'
              }
            },
            {
              path: '/metallic/:versionId/',
              name: 'M Detail',
              component: () => import(/* webpackChunkName: "designerDetail" */ '@/views/designers/DesignerDetail'),
              props: { versionId: true},
              meta: {
                label: 'Detail',
                requiresAuth: true, 
                permission: 'Designers',
                state: 'detail'
              }
            },
          ]
        },
        {
          path: 'watercolor',
          redirect: '/watercolor/pending',
          name: 'Watercolor D',
          meta: {
            label: 'Watercolor Edition',
          },
          component: {
            render (c) { return c('router-view') }
          },
          children: [
            {
              path: '/watercolor/pending/',
              name: 'W Pending ',
              component: () => import(/* webpackChunkName: "designer" */ '@/views/designers/Designers'),
              meta: {
                label: 'Pending',
                requiresAuth: true, 
                permission: 'Designers',
                state: 'list'
              }
            },
            {
              path: '/watercolor/completed/',
              name: 'W Completed',
              component: () => import(/* webpackChunkName: "designer" */ '@/views/designers/Designers'),
              meta: {
                label: 'Completed',
                requiresAuth: true, 
                permission: 'Designers',
                state: 'list'
              }
            },
            {
              path: '/watercolor/:versionId/',
              name: 'W Detail',
              component: () => import(/* webpackChunkName: "designerDetail" */ '@/views/designers/DesignerDetail'),
              props: { versionId: true},
              meta: {
                label: 'Detail',
                requiresAuth: true, 
                permission: 'Designers',
                state: 'detail'
              }
            },
          ]
        },
        {
          path: 'vintage',
          redirect: '/vintage/pending',
          name: 'Vintage D',
          meta: {
            label: 'Vintage Edition',
          },
          component: {
            render (c) { return c('router-view') }
          },
          children: [
            {
              path: '/vintage/pending/',
              name: 'V Pending ',
              component: () => import(/* webpackChunkName: "designer" */ '@/views/designers/Designers'),
              meta: {
                label: 'Pending',
                requiresAuth: true, 
                permission: 'Designers',
                state: 'list'
              }
            },
            {
              path: '/vintage/completed/',
              name: 'V Completed',
              component: () => import(/* webpackChunkName: "designer" */ '@/views/designers/Designers'),
              meta: {
                label: 'Completed',
                requiresAuth: true, 
                permission: 'Designers',
                state: 'list'
              }
            },
            {
              path: '/vintage/:versionId/',
              name: 'V Detail',
              component: () => import(/* webpackChunkName: "designerDetail" */ '@/views/designers/DesignerDetail'),
              props: { versionId: true},
              meta: {
                label: 'Detail',
                requiresAuth: true, 
                permission: 'Designers',
                state: 'detail'
              }
            },
          ]
        },
        {
          path: 'photostyle',
          redirect: '/photostyle/pending',
          name: 'Photostyle D',
          meta: {
            label: 'Photostyle Edition',
          },
          component: {
            render (c) { return c('router-view') }
          },
          children: [
            {
              path: '/photostyle/pending/',
              name: 'Ps Pending ',
              component: () => import(/* webpackChunkName: "designer" */ '@/views/designers/Designers'),
              meta: {
                label: 'Pending',
                requiresAuth: true, 
                permission: 'Designers',
                state: 'list'
              }
            },
            {
              path: '/photostyle/completed/',
              name: 'Ps Completed',
              component: () => import(/* webpackChunkName: "designer" */ '@/views/designers/Designers'),
              meta: {
                label: 'Completed',
                requiresAuth: true, 
                permission: 'Designers',
                state: 'list'
              }
            },
            {
              path: '/photostyle/:versionId/',
              name: 'Ps Detail',
              component: () => import(/* webpackChunkName: "designerDetail" */ '@/views/designers/DesignerDetail'),
              props: { versionId: true},
              meta: {
                label: 'Detail',
                requiresAuth: true, 
                permission: 'Designers',
                state: 'detail'
              }
            },
          ]
        },
        {
          path: 'social-profile',
          redirect: '/social-profile/pending',
          name: 'Social Profile D',
          meta: {
            label: 'Social Profile Pack',
          },
          component: {
            render (c) { return c('router-view') }
          },
          children: [
            {
              path: '/social-profile/pending/',
              name: 'So Pending ',
              component: () => import(/* webpackChunkName: "designer" */ '@/views/designers/Designers'),
              meta: {
                label: 'Pending',
                requiresAuth: true, 
                permission: 'Designers',
                state: 'list'
              }
            },
            {
              path: '/social-profile/completed/',
              name: 'So Completed',
              component: () => import(/* webpackChunkName: "designer" */ '@/views/designers/Designers'),
              meta: {
                label: 'Completed',
                requiresAuth: true, 
                permission: 'Designers',
                state: 'list'
              }
            },
            {
              path: '/social-profile/:versionId/',
              name: 'So Detail',
              component: () => import(/* webpackChunkName: "designerDetail" */ '@/views/designers/DesignerDetail'),
              props: { versionId: true},
              meta: {
                label: 'Detail',
                requiresAuth: true, 
                permission: 'Designers',
                state: 'detail'
              }
            },
          ]
        },
        {
          path: 'all-purpose-pack',
          redirect: '/all-purpose-pack/pending',
          name: 'All Purpose Pack D',
          meta: {
            label: 'All Purpose Pack',
          },
          component: {
            render (c) { return c('router-view') }
          },
          children: [
            {
              path: '/all-purpose-pack/pending/',
              name: 'Alp Pending ',
              component: () => import(/* webpackChunkName: "designer" */ '@/views/designers/Designers'),
              meta: {
                label: 'Pending',
                requiresAuth: true, 
                permission: 'Designers',
                state: 'list'
              }
            },
            {
              path: '/all-purpose-pack/completed/',
              name: 'Alp Completed',
              component: () => import(/* webpackChunkName: "designer" */ '@/views/designers/Designers'),
              meta: {
                label: 'Completed',
                requiresAuth: true, 
                permission: 'Designers',
                state: 'list'
              }
            },
            {
              path: '/all-purpose-pack/:versionId/',
              name: 'Alp Detail',
              component: () => import(/* webpackChunkName: "designerDetail" */ '@/views/designers/DesignerDetail'),
              props: { versionId: true},
              meta: {
                label: 'Detail',
                requiresAuth: true, 
                permission: 'Designers',
                state: 'detail'
              }
            },
          ]
        },
        {
          path: 'smart-email-signature',
          redirect: '/smart-email-signature/pending',
          name: 'Smart Email Signature D',
          meta: {
            label: 'Smart Email Signature',
          },
          component: {
            render (c) { return c('router-view') }
          },
          children: [
            {
              path: '/smart-email-signature/pending/',
              name: 'Sm Pending ',
              component: () => import(/* webpackChunkName: "designer" */ '@/views/designers/Designers'),
              meta: {
                label: 'Pending',
                requiresAuth: true, 
                permission: 'Designers',
                state: 'list'
              }
            },
            {
              path: '/smart-email-signature/completed/',
              name: 'Sm Completed',
              component: () => import(/* webpackChunkName: "designer" */ '@/views/designers/Designers'),
              meta: {
                label: 'Completed',
                requiresAuth: true, 
                permission: 'Designers',
                state: 'list'
              }
            },
            {
              path: '/smart-email-signature/:versionId/',
              name: 'Sm Detail',
              component: () => import(/* webpackChunkName: "designerDetail" */ '@/views/designers/DesignerDetail'),
              props: { versionId: true},
              meta: {
                label: 'Detail',
                requiresAuth: true, 
                permission: 'Designers',
                state: 'detail'
              }
            },
          ]
        },
        {
          path: 'smart-business-card',
          redirect: '/smart-business-card/pending',
          name: 'Business Card D',
          meta: {
            label: 'Business Card',
          },
          component: {
            render (c) { return c('router-view') }
          },
          children: [
            {
              path: '/smart-business-card/pending/',
              name: 'Bc Pending ',
              component: () => import(/* webpackChunkName: "designer" */ '@/views/designers/Designers'),
              meta: {
                label: 'Pending',
                requiresAuth: true, 
                permission: 'Designers',
                state: 'list'
              }
            },
            {
              path: '/smart-business-card/completed/',
              name: 'Bc Completed',
              component: () => import(/* webpackChunkName: "designer" */ '@/views/designers/Designers'),
              meta: {
                label: 'Completed',
                requiresAuth: true, 
                permission: 'Designers',
                state: 'list'
              }
            },
            {
              path: '/smart-business-card/:versionId/',
              name: 'Bc Detail',
              component: () => import(/* webpackChunkName: "designerDetail" */ '@/views/designers/DesignerDetail'),
              props: { versionId: true},
              meta: {
                label: 'Detail',
                requiresAuth: true, 
                permission: 'Designers',
                state: 'detail'
              }
            },
          ]
        },
        {
          path: 'social-media',
          redirect: '/social-media/pending',
          name: 'Social Media Kit D',
          meta: {
            label: 'Social Media Kit',
          },
          component: {
            render (c) { return c('router-view') }
          },
          children: [
            {
              path: '/social-media/pending/',
              name: 'Smk Pending ',
              component: () => import(/* webpackChunkName: "designer" */ '@/views/designers/Designers'),
              meta: {
                label: 'Pending',
                requiresAuth: true, 
                permission: 'Designers',
                state: 'list'
              }
            },
            {
              path: '/social-media/completed/',
              name: 'Smk Completed',
              component: () => import(/* webpackChunkName: "designer" */ '@/views/designers/Designers'),
              meta: {
                label: 'Completed',
                requiresAuth: true, 
                permission: 'Designers',
                state: 'list'
              }
            },
            {
              path: '/social-media/:versionId/',
              name: 'Smk Detail',
              component: () => import(/* webpackChunkName: "designerDetail" */ '@/views/designers/DesignerDetail'),
              props: { versionId: true},
              meta: {
                label: 'Detail',
                requiresAuth: true, 
                permission: 'Designers',
                state: 'detail'
              }
            },
          ]
        },
        {
          path: 'qr-card',
          redirect: '/qr-card/pending',
          name: 'QR Business Card D',
          meta: {
            label: 'QR Business Card',
          },
          component: {
            render (c) { return c('router-view') }
          },
          children: [
            {
              path: '/qr-card/pending/',
              name: 'QR Pending ',
              component: () => import(/* webpackChunkName: "designer" */ '@/views/designers/Designers'),
              meta: {
                label: 'Pending',
                requiresAuth: true, 
                permission: 'Designers',
                state: 'list'
              }
            },
            {
              path: '/qr-card/completed/',
              name: 'QR Completed',
              component: () => import(/* webpackChunkName: "designer" */ '@/views/designers/Designers'),
              meta: {
                label: 'Completed',
                requiresAuth: true, 
                permission: 'Designers',
                state: 'list'
              }
            },
            {
              path: '/qr-card/:versionId/',
              name: 'QR Detail',
              component: () => import(/* webpackChunkName: "designerDetail" */ '@/views/designers/DesignerDetail'),
              props: { versionId: true},
              meta: {
                label: 'Detail',
                requiresAuth: true, 
                permission: 'Designers',
                state: 'detail'
              }
            },
          ]
        },
        {
          path: 'avatarme',
          redirect: '/avatarme/pending',
          name: 'Avatar Me D',
          meta: {
            label: 'Avatar Me',
          },
          component: {
            render (c) { return c('router-view') }
          },
          children: [
            {
              path: '/avatarme/pending/',
              name: 'Av Pending ',
              component: () => import(/* webpackChunkName: "designer" */ '@/views/designers/Designers'),
              meta: {
                label: 'Pending',
                requiresAuth: true, 
                permission: 'Designers',
                state: 'list'
              }
            },
            {
              path: '/avatarme/completed/',
              name: 'Av Completed',
              component: () => import(/* webpackChunkName: "designer" */ '@/views/designers/Designers'),
              meta: {
                label: 'Completed',
                requiresAuth: true, 
                permission: 'Designers',
                state: 'list'
              }
            },
            {
              path: '/avatarme/:versionId/',
              name: 'Av Detail',
              component: () => import(/* webpackChunkName: "designerDetail" */ '@/views/designers/DesignerDetail'),
              props: { versionId: true},
              meta: {
                label: 'Detail',
                requiresAuth: true, 
                permission: 'Designers',
                state: 'detail'
              }
            },
          ]
        },
        {
          path: 'silver-gold',
          redirect: '/silver-gold/pending',
          name: 'Silver and Gold D',
          meta: {
            label: 'Silver & Gold',
          },
          component: {
            render (c) { return c('router-view') }
          },
          children: [
            {
              path: '/silver-gold/pending/',
              name: 'Sg Pending ',
              component: () => import(/* webpackChunkName: "designer" */ '@/views/designers/Designers'),
              meta: {
                label: 'Pending',
                requiresAuth: true, 
                permission: 'Designers',
                state: 'list'
              }
            },
            {
              path: '/silver-gold/completed/',
              name: 'Sg Completed',
              component: () => import(/* webpackChunkName: "designer" */ '@/views/designers/Designers'),
              meta: {
                label: 'Completed',
                requiresAuth: true, 
                permission: 'Designers',
                state: 'list'
              }
            },
            {
              path: '/silver-gold/:versionId/',
              name: 'Sg Detail',
              component: () => import(/* webpackChunkName: "designerDetail" */ '@/views/designers/DesignerDetail'),
              props: { versionId: true},
              meta: {
                label: 'Detail',
                requiresAuth: true, 
                permission: 'Designers',
                state: 'detail'
              }
            },
          ]
        },
        {
          path: 'highlight-cover',
          redirect: '/highlight-cover/pending',
          name: 'Highlight Covers D',
          meta: {
            label: 'Highlight Covers',
          },
          component: {
            render (c) { return c('router-view') }
          },
          children: [
            {
              path: '/highlight-cover/pending/',
              name: 'Hc Pending ',
              component: () => import(/* webpackChunkName: "designer" */ '@/views/designers/Designers'),
              meta: {
                label: 'Pending',
                requiresAuth: true, 
                permission: 'Designers',
                state: 'list'
              }
            },
            {
              path: '/highlight-cover/completed/',
              name: 'Hc Completed',
              component: () => import(/* webpackChunkName: "designer" */ '@/views/designers/Designers'),
              meta: {
                label: 'Completed',
                requiresAuth: true, 
                permission: 'Designers',
                state: 'list'
              }
            },
            {
              path: '/silver-gold/:versionId/',
              name: 'Hc Detail',
              component: () => import(/* webpackChunkName: "designerDetail" */ '@/views/designers/DesignerDetail'),
              props: { versionId: true},
              meta: {
                label: 'Detail',
                requiresAuth: true, 
                permission: 'Designers',
                state: 'detail'
              }
            },
          ]
        },
        {
          path: 'photologo-skins-bundle',
          redirect: '/photologo-skins-bundle/pending',
          name: 'Skins Bundle D',
          meta: {
            label: 'Skins Bundle',
          },
          component: {
            render (c) { return c('router-view') }
          },
          children: [
            {
              path: '/photologo-skins-bundle/pending/',
              name: 'Sb Pending',
              component: () => import(/* webpackChunkName: "designer" */ '@/views/designers/Designers'),
              meta: {
                label: 'Pending',
                requiresAuth: true, 
                permission: 'Designers',
                state: 'list'
              }
            },
            {
              path: '/photologo-skins-bundle/completed/',
              name: 'Sb Completed',
              component: () => import(/* webpackChunkName: "designer" */ '@/views/designers/Designers'),
              meta: {
                label: 'Completed',
                requiresAuth: true, 
                permission: 'Designers',
                state: 'list'
              }
            },
            {
              path: '/photologo-skins-bundle/:versionId/',
              name: 'Sb Detail',
              component: () => import(/* webpackChunkName: "designerDetail" */ '@/views/designers/DesignerDetail'),
              props: { versionId: true},
              meta: {
                label: 'Detail',
                requiresAuth: true, 
                permission: 'Designers',
                state: 'detail'
              }
            },
          ]
        },
        {
          path: 'graphic-add-on',
          redirect: '/graphic-add-on/pending',
          name: 'Graphic Add-on D',
          meta: {
            label: 'Graphic Add-on',
          },
          component: {
            render (c) { return c('router-view') }
          },
          children: [
            {
              path: '/graphic-add-on/pending/',
              name: 'Ga Pending',
              component: () => import(/* webpackChunkName: "designer" */ '@/views/designers/Designers'),
              meta: {
                label: 'Pending',
                requiresAuth: true, 
                permission: 'Designers',
                state: 'list'
              }
            },
            {
              path: '/graphic-add-on/completed/',
              name: 'Ga Completed',
              component: () => import(/* webpackChunkName: "designer" */ '@/views/designers/Designers'),
              meta: {
                label: 'Completed',
                requiresAuth: true, 
                permission: 'Designers',
                state: 'list'
              }
            },
            {
              path: '/graphic-add-on/:versionId/',
              name: 'Ga Detail',
              component: () => import(/* webpackChunkName: "designerDetail" */ '@/views/designers/DesignerDetail'),
              props: { versionId: true},
              meta: {
                label: 'Detail',
                requiresAuth: true, 
                permission: 'Designers',
                state: 'detail'
              }
            },
          ]
        },
        {
          path: 'signaturebrand',
          redirect: '/signaturebrand/pending',
          name: 'Signature Brand D',
          meta: {
            label: 'Signature Brand',
          },
          component: {
            render (c) { return c('router-view') }
          },
          children: [
            {
              path: '/signaturebrand/pending/',
              name: 'Sgb Pending',
              component: () => import(/* webpackChunkName: "designer" */ '@/views/designers/Designers'),
              meta: {
                label: 'Pending',
                requiresAuth: true, 
                permission: 'Designers',
                state: 'list'
              }
            },
            {
              path: '/signaturebrand/completed/',
              name: 'Sgb Completed',
              component: () => import(/* webpackChunkName: "designer" */ '@/views/designers/Designers'),
              meta: {
                label: 'Completed',
                requiresAuth: true, 
                permission: 'Designers',
                state: 'list'
              }
            },
            {
              path: '/signaturebrand/:versionId/',
              name: 'Sgb Detail',
              component: () => import(/* webpackChunkName: "designerDetail" */ '@/views/designers/DesignerDetail'),
              props: { versionId: true},
              meta: {
                label: 'Detail',
                requiresAuth: true, 
                permission: 'Designers',
                state: 'detail'
              }
            },
          ]
        },
        {
          path: 'sb-kit',
          redirect: '/sb-kit/pending',
          name: 'Signature Brand Kit D',
          component: {
            render (c) { return c('router-view') }
          },
          children: [
            {
              path: '/sb-kit/pending/',
              name: 'SBK Pending',
              component: () => import(/* webpackChunkName: "designer" */ '@/views/designers/Designers'),
              meta: {
                label: 'Pending',
                requiresAuth: true, 
                permission: 'Designers',
                state: 'list'
              }
            },
            {
              path: '/sb-kit/completed/',
              name: 'SBK Completed',
              component: () => import(/* webpackChunkName: "designer" */ '@/views/designers/Designers'),
              meta: {
                label: 'Completed',
                requiresAuth: true, 
                permission: 'Designers',
                state: 'list'
              }
            },
            {
              path: '/sb-kit/:versionId/',
              name: 'SBK Detail',
              component: () => import(/* webpackChunkName: "designerDetail" */ '@/views/designers/DesignerDetail'),
              props: { versionId: true},
              meta: {
                label: 'Detail',
                requiresAuth: true, 
                permission: 'Designers',
                state: 'detail'
              }
            },
          ]
        },
        {
          path: 'pl-brand-seal',
          redirect: '/pl-brand-seal/pending',
          name: 'Brand Seal D',
          component: {
            render (c) { return c('router-view') }
          },
          children: [
            {
              path: '/pl-brand-seal/pending/',
              name: 'BRS Pending',
              component: () => import(/* webpackChunkName: "designer" */ '@/views/designers/Designers'),
              meta: {
                label: 'Pending',
                requiresAuth: true, 
                permission: 'Designers',
                state: 'list'
              }
            },
            {
              path: '/pl-brand-seal/completed/',
              name: 'BRS Completed',
              component: () => import(/* webpackChunkName: "designer" */ '@/views/designers/Designers'),
              meta: {
                label: 'Completed',
                requiresAuth: true, 
                permission: 'Designers',
                state: 'list'
              }
            },
            {
              path: '/pl-brand-seal/:versionId/',
              name: 'BRS Detail',
              component: () => import(/* webpackChunkName: "designerDetail" */ '@/views/designers/DesignerDetail'),
              props: { versionId: true},
              meta: {
                label: 'Detail',
                requiresAuth: true, 
                permission: 'Designers',
                state: 'detail'
              }
            },
          ]
        },
        {
          path: 'pl-signature-tutorial',
          redirect: '/pl-signature-tutorial/pending',
          name: 'Signature Tutorial D',
          component: {
            render (c) { return c('router-view') }
          },
          children: [
            {
              path: '/pl-signature-tutorial/pending/',
              name: 'Sgt Pending',
              component: () => import(/* webpackChunkName: "designer" */ '@/views/designers/Designers'),
              meta: {
                label: 'Pending',
                requiresAuth: true, 
                permission: 'Designers',
                state: 'list'
              }
            },
            {
              path: '/pl-signature-tutorial/completed/',
              name: 'Sgt Completed',
              component: () => import(/* webpackChunkName: "designer" */ '@/views/designers/Designers'),
              meta: {
                label: 'Completed',
                requiresAuth: true, 
                permission: 'Designers',
                state: 'list'
              }
            },
            {
              path: '/pl-signature-tutorial/:versionId/',
              name: 'Sgt Detail',
              component: () => import(/* webpackChunkName: "designerDetail" */ '@/views/designers/DesignerDetail'),
              props: { versionId: true},
              meta: {
                label: 'Detail',
                requiresAuth: true, 
                permission: 'Designers',
                state: 'detail'
              }
            },
          ]
        },
        {
          path: 'signature-practice',
          redirect: '/signature-practice/pending',
          name: 'Signature Practice PDF D',
          component: {
            render (c) { return c('router-view') }
          },
          children: [
            {
              path: '/signature-practice/pending/',
              name: 'Spf Pending',
              component: () => import(/* webpackChunkName: "designer" */ '@/views/designers/Designers'),
              meta: {
                label: 'Pending',
                requiresAuth: true, 
                permission: 'Designers',
                state: 'list'
              }
            },
            {
              path: '/signature-practice/completed/',
              name: 'Spf Completed',
              component: () => import(/* webpackChunkName: "designer" */ '@/views/designers/Designers'),
              meta: {
                label: 'Completed',
                requiresAuth: true, 
                permission: 'Designers',
                state: 'list'
              }
            },
            {
              path: '/signature-practice/:versionId/',
              name: 'Spf Detail',
              component: () => import(/* webpackChunkName: "designerDetail" */ '@/views/designers/DesignerDetail'),
              props: { versionId: true},
              meta: {
                label: 'Detail',
                requiresAuth: true, 
                permission: 'Designers',
                state: 'detail'
              }
            },
          ]
        },
        {
          path: 'sig-variations-trio',
          redirect: '/sig-variations-trio/pending',
          name: 'Signature Variations Trio D',
          component: {
            render (c) { return c('router-view') }
          },
          children: [
            {
              path: '/sig-variations-trio/pending/',
              name: 'SVT Pending',
              component: () => import(/* webpackChunkName: "designer" */ '@/views/designers/Designers'),
              meta: {
                label: 'Pending',
                requiresAuth: true, 
                permission: 'Designers',
                state: 'list'
              }
            },
            {
              path: '/sig-variations-trio/completed/',
              name: 'SVT Completed',
              component: () => import(/* webpackChunkName: "designer" */ '@/views/designers/Designers'),
              meta: {
                label: 'Completed',
                requiresAuth: true, 
                permission: 'Designers',
                state: 'list'
              }
            },
            {
              path: '/sig-variations-trio/:versionId/',
              name: 'SVT Detail',
              component: () => import(/* webpackChunkName: "designerDetail" */ '@/views/designers/DesignerDetail'),
              props: { versionId: true},
              meta: {
                label: 'Detail',
                requiresAuth: true, 
                permission: 'Designers',
                state: 'detail'
              }
            },
          ]
        },
        {
          path: 'animators',
          redirect: '/animators/pending',
          name: 'Animators',
          component: {
            render (c) { return c('router-view') }
          },
          children: [
            {
              path: '/animators/pending/',
              name: 'A Pending',
              component: () => import(/* webpackChunkName: "designer" */ '@/views/designers/Designers'),
              meta: {
                label: 'Pending',
                requiresAuth: true, 
                permission: 'Designers',
                state: 'list'
              }
            },
            {
              path: '/animators/completed/',
              name: 'A Completed',
              component: () => import(/* webpackChunkName: "designer" */ '@/views/designers/Designers'),
              meta: {
                label: 'Completed',
                requiresAuth: true, 
                permission: 'Designers',
                state: 'list'
              }
            },
            {
              path: '/animators/:versionId/',
              name: 'A Detail',
              component: () => import(/* webpackChunkName: "designerDetail" */ '@/views/designers/DesignerDetail'),
              props: { versionId: true},
              meta: {
                label: 'Detail',
                requiresAuth: true, 
                permission: 'Designers',
                state: 'detail'
              }
            },
          ]
        },
        {
          path: '3d-kinetic',
          redirect: '/3d-kinetic/pending',
          name: '3D Kinetic',
          component: {
            render (c) { return c('router-view') }
          },
          children: [
            {
              path: '/3d-kinetic/pending/',
              name: 'Ki Pending',
              component: () => import(/* webpackChunkName: "designer" */ '@/views/designers/Designers'),
              meta: {
                label: 'Pending',
                requiresAuth: true, 
                permission: 'Designers',
                state: 'list'
              }
            },
            {
              path: '/3d-kinetic/completed/',
              name: 'Ki Completed',
              component: () => import(/* webpackChunkName: "designer" */ '@/views/designers/Designers'),
              meta: {
                label: 'Completed',
                requiresAuth: true, 
                permission: 'Designers',
                state: 'list'
              }
            },
            {
              path: '/3d-kinetic/:versionId/',
              name: 'Ki Detail',
              component: () => import(/* webpackChunkName: "designerDetail" */ '@/views/designers/DesignerDetail'),
              props: { versionId: true},
              meta: {
                label: 'Detail',
                requiresAuth: true, 
                permission: 'Designers',
                state: 'detail'
              }
            },
          ]
        },
        {
          path: 'electric-logo',
          redirect: '/electric-logo/pending',
          name: 'Electric logo',
          component: {
            render (c) { return c('router-view') }
          },
          children: [
            {
              path: '/electric-logo/pending/',
              name: 'Elc Pending',
              component: () => import(/* webpackChunkName: "designer" */ '@/views/designers/Designers'),
              meta: {
                label: 'Pending',
                requiresAuth: true, 
                permission: 'Designers',
                state: 'list'
              }
            },
            {
              path: '/electric-logo/completed/',
              name: 'Elc Completed',
              component: () => import(/* webpackChunkName: "designer" */ '@/views/designers/Designers'),
              meta: {
                label: 'Completed',
                requiresAuth: true, 
                permission: 'Designers',
                state: 'list'
              }
            },
            {
              path: '/electric-logo/:versionId/',
              name: 'Elc Detail',
              component: () => import(/* webpackChunkName: "designerDetail" */ '@/views/designers/DesignerDetail'),
              props: { versionId: true},
              meta: {
                label: 'Detail',
                requiresAuth: true, 
                permission: 'Designers',
                state: 'detail'
              }
            },
          ]
        },
        {
          path: 'stampFactory',
          redirect: '/stamp-factory/not-yet-submitted/',
          name: 'StampFactory',
          meta: {
            label: 'Stamps',
          },
          component: {
            render (c) { return c('router-view') }
          },
          children: [
            {
              path: '/stamp-factory/not-yet-submitted/',
              name: 'S Not Yet Submitted',
              component: () => import(/* webpackChunkName: "stamps" */ '@/views/stamp-factory/StampFactory'),
              meta: {
                label: 'Not Yet Submitted',
                requiresAuth: true, 
                permission: 'Stamp Factory',
              }
            },
            {
              path: '/stamp-factory/submitted/',
              name: 'S Submitted',
              component: () => import(/* webpackChunkName: "stamps" */ '@/views/stamp-factory/StampFactory'),
              meta: {
                label: 'Submitted',
                requiresAuth: true, 
                permission: 'Stamp Factory',
              }
            },
            {
              path: '/stamp-factory/fulfilled/',
              name: 'S Fulfilled',
              component: () => import(/* webpackChunkName: "stamps" */ '@/views/stamp-factory/StampFactory'),
              meta: {
                label: 'Fulfilled',
                requiresAuth: true, 
                permission: 'Stamp Factory',
              }
            },
            {
              path: '/stamp-factory/:id',
              name: 'S Detail',
              component: () => import(/* webpackChunkName: "stampsDetail" */ '@/views/stamp-factory/StampFactoryDetail'),
              props: true,
              meta: {
                label: 'Detail',
                requiresAuth: true, 
                permission: 'Stamp Factory',
              }
            },
          ]
        },
      ]
    },
    {
      path: '/pages',
      redirect: '/pages/login',
      name: 'Pages',
      component: {
        render (c) { return c('router-view') }
      },
      children: [
        {
          path: 'login',
          name: 'Login',
          component: () => import(/* webpackChunkName: "Login" */ '@/views/auth/Login')
        }
      ]
    },
    {
      path: '*',
      redirect: '*',
      name: '404',
      component: {
        render (c) { return c('router-view') }
      },
      children: [
        {
          path: '*',
          name: 'Page404',
          component: () => import(/* webpackChunkName: "404" */ /* webpackMode: "lazy" */ '@/views/pages/Page404')
        },
        {
          path: '500',
          name: 'Page500',
          component: () => import(/* webpackChunkName: "500" */ '@/views/pages/Page500')
        },
      ]
    }
  ]
}

