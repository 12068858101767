<template>
  <router-view></router-view>
</template>

<script>
export default {
  name: 'App'
}
</script>

<style lang="scss">

  /* Import Font Awesome Icons Set */
  $fa-font-path: '~font-awesome/fonts/';
  @import '~font-awesome/scss/font-awesome.scss';
  /* Import Bootstrap Vue Styles */
  @import '~bootstrap-vue/dist/bootstrap-vue.css';
  // Import Main styles for this application
  @import 'assets/scss/style';
  // vue-snotify
  @import '~vue-snotify/styles/dark.css';
  //Google Fonts - Muli
  @import url('https://fonts.googleapis.com/css2?family=Muli:wght@300;400;600;700&display=swap');
</style>
