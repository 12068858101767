import { productTypes }  from './productTypes';

export const productTypeMapping = {
  [productTypes.photologo.type]: productTypes.photologo.pendingFulfillmentsKey,
  [productTypes.lovemark.type]: productTypes.lovemark.pendingFulfillmentsKey,
  [productTypes.iconic.type]: productTypes.iconic.pendingFulfillmentsKey,
  [productTypes.initials.type]: productTypes.initials.pendingFulfillmentsKey,
  [productTypes.extension.type]: productTypes.extension.pendingFulfillmentsKey,
  [productTypes.metallic.type]: productTypes.metallic.pendingFulfillmentsKey,
  [productTypes.watercolor.type]: productTypes.watercolor.pendingFulfillmentsKey,
  [productTypes.vintage.type]: productTypes.vintage.pendingFulfillmentsKey,
  [productTypes.photostyle.type]: productTypes.photostyle.pendingFulfillmentsKey,
  [productTypes.social.type]: productTypes.social.pendingFulfillmentsKey,
  [productTypes.AllPurpose.type]: productTypes.AllPurpose.pendingFulfillmentsKey,
  [productTypes.smartEmail.type]: productTypes.smartEmail.pendingFulfillmentsKey,
  [productTypes.businessCard.type]: productTypes.businessCard.pendingFulfillmentsKey,
  [productTypes.socialMediaKit.type]: productTypes.socialMediaKit.pendingFulfillmentsKey,
  [productTypes.qrBusinessCard.type]: productTypes.qrBusinessCard.pendingFulfillmentsKey,
  [productTypes.avatarMe.type]: productTypes.avatarMe.pendingFulfillmentsKey,
  [productTypes.kinetic.type]: productTypes.kinetic.pendingFulfillmentsKey,
  [productTypes.electric.type]: productTypes.electric.pendingFulfillmentsKey,
  [productTypes.silverAndGold.type]: productTypes.silverAndGold.pendingFulfillmentsKey,
  [productTypes.highlightCovers.type]: productTypes.highlightCovers.pendingFulfillmentsKey,
  [productTypes.skinsBundle.type]: productTypes.skinsBundle.pendingFulfillmentsKey,
  [productTypes.graphicAddon.type]: productTypes.graphicAddon.pendingFulfillmentsKey,
  [productTypes.photologoSignatureBrand.type]: productTypes.photologoSignatureBrand.pendingFulfillmentsKey,
  [productTypes.signatureBrand.type]: productTypes.signatureBrand.pendingFulfillmentsKey,
  [productTypes.signatureBrandKit.type]: productTypes.signatureBrandKit.pendingFulfillmentsKey,
  [productTypes.brandSeal.type]: productTypes.brandSeal.pendingFulfillmentsKey,
  [productTypes.signatureTutorial.type]: productTypes.signatureTutorial.pendingFulfillmentsKey,
  [productTypes.signaturePractice.type]: productTypes.signaturePractice.pendingFulfillmentsKey,
  [productTypes.signatureVariationsTrio.type]: productTypes.signatureVariationsTrio.pendingFulfillmentsKey,
  [productTypes.initialsPack.type]: productTypes.initialsPack.pendingFulfillmentsKey,
  [productTypes.brandSealPack.type]: productTypes.brandSealPack.pendingFulfillmentsKey,
  [productTypes.animations.type]: productTypes.animations.pendingFulfillmentsKey,
  [productTypes.stamps.type]: productTypes.stamps.pendingFulfillmentsKey,
  [productTypes.ultimateSignatureKit.type]: productTypes.ultimateSignatureKit.pendingFulfillmentsKey
}