export default {
  namespaced: true,
  state: {
    totalNotifications: 0,
    newMessage: false,
  },
  mutations:{
    setTotalNotifications(state,totalNotifications){
      state.totalNotifications = totalNotifications
    },
  }
}