import 'core-js/stable'
import "regenerator-runtime/runtime";
import Vue from 'vue'
import App from './App'
import router from './router'
import CoreuiVue from '@coreui/vue'
import { iconsSet as icons } from './assets/icons/icons.js'
import store from './store'
import BootstrapVue from 'bootstrap-vue'
import VueCookie from 'vue-cookie'
import { createProvider } from './vue-apollo'
import { ClientTable } from 'vue-tables-2'
import vSelect from 'vue-select'
import VueLodash from 'vue-lodash'
import Snotify from 'vue-snotify';
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import vueAwesomeCountdown from 'vue-awesome-countdown'
import Rollbar from 'vue-rollbar'
import VueMasonry from 'vue-masonry-css'
import "vue-select/dist/vue-select.css"
import uniqBy from 'lodash/uniqBy'
import groupBy from 'lodash/groupBy'
import random from 'lodash/random'
import CXSidebarNavDropdown from './containers/Sidebar/CXSidebarNavDropdown'

const options = { name: 'custom', lodash: { uniqBy, groupBy, random} }
Vue.use(BootstrapVue)
Vue.use(VueCookie)
Vue.use(ClientTable)
Vue.use(VueLodash, options)
Vue.use(Snotify)
Vue.use(vueAwesomeCountdown, 'vac')
Vue.component('v-select', vSelect)
Vue.use(VueMasonry)
Vue.component('CXSidebarNavDropdown', CXSidebarNavDropdown)
const environment = process.env.NODE_ENV
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.tz.setDefault("Europe/Lisbon")
//Rollbar 
Vue.use(Rollbar, {
  accessToken: 'f15d6b91cae44d89a089dea003f8bca5',
  captureUncaught: window.location.hostname != 'localhost' ? true : false,
  captureUnhandledRejections: window.location.hostname != 'localhost' ? true : false,
  enabled: window.location.hostname != 'localhost' ? true : false,
  source_map_enabled: true,
  environment: environment,
  itemsPerMinute: 20,
  maxItems: 20,
  payload: {
    client: {
         javascript: {
            code_version: '1.0'
         }
    },
    person:{
      email: Vue.cookie.get('wpl_username')
    }
  }
});

Vue.config.errorHandler = (err) => {
  Vue.rollbar.error(err)
  console.log('Exception config: ', err)
  throw err; // rethrow
};
window.onerror = function(message, source, lineno, colno, error) {
  console.log('Exception on error: ', error,message,source)
  if(message.startsWith != 'ResizeObserver'){
    if(error != null)
      Vue.rollbar.error('Exception on error:',{'message': message},error)
  }
}
Vue.config.performance = true
Vue.use(CoreuiVue)
Vue.prototype.$log = console.log.bind(console)
Vue.config.devtools = true
Vue.config.productionTip = false;
//Middleware
//move to file
router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    if (Vue.cookie.get('wpl_token') && Vue.cookie.get('wpl_username') && Vue.cookie.get('wpl_user_group')) {
      switch(to.meta.permission){
        case 'general':
          next()
        break;
        case 'RepDes':
          if(Vue.cookie.get('wpl_user_group') === 'Representatives' || Vue.cookie.get('wpl_user_group') === 'Designers'){
            next()
          }else{
            alert('You do not have permission to Representatives Dashboard')
            next({ name: 'Login' })
          }
        break;
        case 'Representatives':
          if(Vue.cookie.get('wpl_user_group') === to.meta.permission ){
            next()
          }else{
            alert('You do not have permission to Representatives Dashboard')
            next({ name: 'Login' })
          }
        break;
        case 'Designers':
          if(Vue.cookie.get('wpl_user_group') === to.meta.permission || Vue.cookie.get('wpl_user_group') === 'Animators'){
            next()
          }else{
            alert('You do not have permission to Designers Dashboard')
            next({ name: 'Login' })
          }
        break;
        case 'Animators':
          if(Vue.cookie.get('wpl_user_group') === to.meta.permission ){
            next()
          }else{
            alert('You do not have permission to Animators Dashboard')
            next({ name: 'Login' })
          }
        break;
        case 'Stamp Factory':
          if(Vue.cookie.get('wpl_user_group').replace(/%20/g, '') === to.meta.permission ){
            next()
          }else{
            alert('You do not have permission to Stamp Factory Dashboard')
            next({ name: 'Login' })
          }
        break;
      }
    }else{
      Vue.cookie.delete('wpl_token')
      Vue.cookie.delete('wpl_user_group')
      Vue.cookie.delete('wpl_username')
      next({ name: 'Login' })
    }
  } else {
    next() // make sure to always call next()!
  }
})
new Vue({
  el: '#app',
  router,
  store,
  icons,
  components: {
    App
  },
  template: '<App/>',
  apolloProvider: createProvider(),
})
