
export default {
  namespaced: true,
  state: {
    params: {},
    versionsStorage: [],
    metadataLogsStorage: []
  },
  mutations:{
    setParams(state,params){
      state.params = params
    },
    setVersionz(state,versionsStorage){
      state.versionsStorage = versionsStorage
    },
    setMetadataLogs(state,metadataLogsStorage){
      state.metadataLogsStorage = metadataLogsStorage
    },
    clearState(state, variable){
      if(variable == 'params')
        state[variable] = {}
      else
        state[variable] = []
    }
  }
}