import { apolloClient } from '@/vue-apollo' 
import GET_TAGS from '../graphql/getFulfillmentsTags.gql'

export default {
  namespaced: true,
  state: {
    tags: [],
  },
  mutations:{
    setTags(state,tags){
      state.tags = tags
    },
  },
  actions: {
    async getTags({commit}) {
      const response = await apolloClient.query({
          query: GET_TAGS,
          fetchPolicy: 'network-only'
      })
      let fulfillmentTags = []
      let tags = []
      fulfillmentTags = response.data.fulfillmentTags.edges.map(function (edge){
        return edge.node
      })
      if(fulfillmentTags != [] && fulfillmentTags.length > 0){
        Object.keys(fulfillmentTags).map(key =>{
              tags.push({id:fulfillmentTags[key].id,label:fulfillmentTags[key].name})
        })
      }
      commit('setTags',tags)
    }
  }
}